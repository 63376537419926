import { Deferred } from '@leon-hub/utils';
import { setupOptions } from '../setupOptions';
import { isAnyResourceLoading, watchFetch } from './internal';
/**
 * Waits for the client network to become idle.
 * @param options
 * @param options.timeout Maximum time to wait.
 * @param options.interval How long to wait until the network is considered idle.
 * @param options.eager If true, immediately resolves on any network activity.
 *
 * @returns Promise resolves time ms remaining before idle state (0 for success).
 */ export const whenClientNetworkIdle = async function() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const { timeout, interval, eager } = setupOptions(options);
    const startTime = Date.now();
    const timeoutTime = startTime + timeout;
    const d = new Deferred();
    let timeoutID;
    let lastConnectionChange = 0;
    function isIdleEnough(now) {
        return lastConnectionChange > 0 && now - lastConnectionChange > interval;
    }
    function update() {
        lastConnectionChange = Date.now();
    }
    const unwatchFetch = watchFetch({
        onBefore: update,
        onAfter: update
    });
    function tearDown(result) {
        unwatchFetch();
        clearTimeout(timeoutID);
        d.resolve(result);
    }
    function getRemainingTime(now) {
        return Math.max(0, now - startTime);
    }
    function runIdleCheck() {
        const now = Date.now();
        try {
            if (timeoutTime > now) tearDown(false);
            else if (isIdleEnough(now) && !isAnyResourceLoading()) tearDown(true);
            else if (eager) tearDown(false);
            else timeoutID = setTimeout(runIdleCheck, interval);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            tearDown(false);
        }
    }
    // Run with micro task.
    Promise.resolve().then(runIdleCheck);
    return d.promise.catch((reason)=>{
        // eslint-disable-next-line no-console
        console.error(reason);
        return false;
    }).then((result)=>result ? 0 : getRemainingTime(Date.now()));
};
