export var TargetParamWithdrawal;
var TargetParamWithdrawal1;
(TargetParamWithdrawal1 = TargetParamWithdrawal || (TargetParamWithdrawal = {}))["USER_PROFILE_HISTORY"] = "userProfileHistoryWithdrawals", TargetParamWithdrawal1["USER_PROFILE"] = "userProfileWithdrawal", TargetParamWithdrawal1["DEPOSIT_LINK"] = "depositListWithdrawalLink";
export var TargetParamHistory;
var TargetParamHistory1;
(TargetParamHistory1 = TargetParamHistory || (TargetParamHistory = {}))["BET_SLIP"] = "betSlipHistory", TargetParamHistory1["REQUEST_WITHDRAWAL"] = "requestWithdrawalHistory", TargetParamHistory1["USER_PROFILE"] = "userProfileHistory";
export var TargetParamEnter;
var TargetParamEnter1;
(TargetParamEnter1 = TargetParamEnter || (TargetParamEnter = {}))["HEADER"] = "headerEnter", TargetParamEnter1["SIDE_MENU"] = "sideMenuEnter", TargetParamEnter1["BOTTOM_LOW_PROFILE"] = "bottomLowProfileEnter", TargetParamEnter1["BOTTOM_LOW_BALANCE"] = "bottomLowBalanceEnter", TargetParamEnter1["BET_SLIP"] = "betSlipLogIn", TargetParamEnter1["REGISTRATION"] = "registrationEnter", TargetParamEnter1["SESSION_EXPIRED"] = "sessionExpiredEnter", TargetParamEnter1["SESSION_EXPIRED_NOTIFICATION"] = "sessionExpiredNotificationEnter", TargetParamEnter1["EMAIL_LOGIN_PAGE"] = "emailLoginPage", TargetParamEnter1["FREE_BET_NEW_USER"] = "freeBetNewUsersEnter", TargetParamEnter1["PROMO_PAGE"] = "promoPageEnter";
export var TargetParamNavigation;
var TargetParamNavigation1;
(TargetParamNavigation1 = TargetParamNavigation || (TargetParamNavigation = {}))["BUTTON_LOW_SLOTS"] = "buttonLowSlots", TargetParamNavigation1["SIDE_MENU_SLOTS"] = "sideMenuSlots", TargetParamNavigation1["SIDE_MENU_LIVE"] = "sideMenuLive", TargetParamNavigation1["SIDE_MENU_VIRTUAL_SPORTS"] = "sideMenuVirtualSports", TargetParamNavigation1["HEADER_VIRTUAL_SPORTS"] = "headerVirtualSports", TargetParamNavigation1["HEADER_LIVE"] = "headerLive", TargetParamNavigation1["HEADER_SLOTS"] = "headerSlots", TargetParamNavigation1["LINK_IN_PROFILE"] = "linkInProfile";
