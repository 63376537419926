import { whenClientActivityIdle } from '@leon-hub/idle';
import { Sentry } from '@leon-hub/sentry';
import { voidify } from '@leon-hub/utils';
export function trackIdleEvents() {
    const interval = 10000;
    let idleStartTime;
    let isRunning = true;
    queueMicrotask(voidify(async ()=>{
        while(isRunning){
            // eslint-disable-next-line no-await-in-loop
            const remains = await whenClientActivityIdle({
                interval,
                eager: true
            });
            if (idleStartTime && remains) {
                const idleTime = Date.now() - idleStartTime;
                idleStartTime = void 0;
                Sentry.addBreadcrumb({
                    category: 'user-lifecycle',
                    message: 'User became active',
                    level: 'info',
                    data: {
                        idleTime
                    }
                });
            } else if (!idleStartTime && !remains) {
                idleStartTime = Date.now();
                Sentry.addBreadcrumb({
                    category: 'user-lifecycle',
                    message: 'User became idle',
                    level: 'info',
                    data: {
                        interval
                    }
                });
            }
        }
    }));
    return ()=>{
        isRunning = false;
    };
}
