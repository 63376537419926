import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export default function createRestorePasswordRoutes(router) {
    router.addModalRoute({
        name: RouteName.RESTORE_PASSWORD,
        path: '/login/restore',
        access: CustomerRouteAccessRole.ANONYMOUS,
        modalPreset: ModalDesktopPreset.SmallDesktopModal,
        prefetch: ()=>import('web/src/modules/restore-password/prefetch/RestoreMainPagePrefetch'),
        component: ()=>import('web/src/modules/restore-password/components/RestorePasswordMainRoutePage/RestorePasswordMainRoutePage.vue'),
        topBar: ()=>import('web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue')
    });
    router.addModalRoute({
        name: RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE,
        path: '/pwdreset/:uid?/:email?',
        access: CustomerRouteAccessRole.ANONYMOUS,
        modalPreset: ModalDesktopPreset.SmallDesktopModal,
        prefetch: ()=>import('web/src/modules/restore-password/prefetch/RestorePasswordLastStepPagePrefetch'),
        component: ()=>import('web/src/modules/restore-password/components/RestorePasswordLastRoutePage/RestorePasswordLastRoutePage.vue'),
        topBar: ()=>import('web/src/modules/restore-password/components/RestorePasswordTopBarRouteComponent/RestorePasswordTopBarRouteComponent.vue')
    });
    router.addModalRoute({
        name: RouteName.UNTRUSTED_DEVICE,
        path: '/untrusted-device/:hashId',
        modalPreset: ModalDesktopPreset.SmallDesktopModal,
        component: ()=>import('web/src/modules/restore-password/submodules/UntrustedDeviceRouteComponent/UntrustedDeviceRouteComponent.vue'),
        title: '{{$t(\'WEB2_PASSWORD_CHANGE\')}}'
    });
    router.addPrefetchRoute({
        name: RouteName.RESTORE_PASSWORD_APP,
        path: '/pwdresetapp/:applink',
        access: CustomerRouteAccessRole.ANONYMOUS,
        prefetch: ()=>import('web/src/modules/restore-password/prefetch/RestorePasswordAppPrefetch')
    });
}
