import { ApiConnectionError, GqlApiAccessDeniedError } from '@leon-hub/api';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { AbstractError, LogLevel } from '@leon-hub/errors';
import { isObject } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { hashString } from '@leon-hub/utils';
import { AccessDeniedModalId } from 'web/src/modules/dialogs/consts';
export function getField(target, key) {
    return isObject(target) ? target[key] : void 0;
}
export function isSilentError(error) {
    return 'silent' in error && !!error.silent;
}
export function getErrorCode(error) {
    return 'code' in error && String(error.code) || 'UNEXPECTED';
}
function getErrorLogLevel(error) {
    if (error instanceof AbstractError) {
        const isSilent = isSilentError(error);
        return isSilent && error instanceof ApiConnectionError ? LogLevel.DEBUG : error.logLevel;
    }
    return LogLevel.ERROR;
}
export function logError(error) {
    const logLevel = getErrorLogLevel(error);
    if (logLevel === LogLevel.DEBUG) {
        logger.debug(error);
        return;
    }
    if (logLevel === LogLevel.INFO) {
        logger.info(error);
        return;
    }
    if (logLevel === LogLevel.WARN) {
        logger.warn(error);
        return;
    }
    logger.error(error);
}
export function getModalId(error, options) {
    return error instanceof GqlApiAccessDeniedError ? AccessDeniedModalId : hashString(`GlobalError${getErrorCode(error)}-${options.confirmMessage}`);
}
export function isMutationRequestError(error) {
    return error instanceof GqlApiBatchedSubRequestError ? error.isMutationRequest() : !!getField(error, 'isMutation');
}
// function isInteractiveError(error: Error): boolean
//   return error instanceof AbstractError && error.isInteractive();
// }
export function isVisibleError(error) {
    return isMutationRequestError(error);
} // || isInteractiveError(error);
