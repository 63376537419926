function processNotPopstateState(to, router, lastState) {
    const newUrl = router.resolve(to).href;
    const lastRoutePosition = lastState?.realPosition || lastState?.position || 0;
    const newHistoryState = {
        ...window.history.state,
        back: router.isReplaced() ? window.history.state?.back : window.history.state?.current,
        current: to.fullPath,
        forward: null,
        // For backward compat with router
        position: lastState?.position,
        realPosition: router.isReplaced() ? lastRoutePosition : lastRoutePosition + 1,
        replaced: router.isReplaced(),
        meta: {
            isModalRoute: true,
            isBackButton: router.isReplaced() ? !!window.history.state?.prevMeta?.isModalRoute : !!lastState?.meta?.isModalRoute
        },
        prevMeta: router.isReplaced() ? window.history.state?.prevMeta : window.history.state?.meta
    };
    if (newHistoryState.replaced) window.history.replaceState(newHistoryState, '', newUrl);
    else window.history.pushState(newHistoryState, '', newUrl);
}
export default function processDesktopHistoryState(to, router, lastState) {
    if (to.meta.modalPreset) {
        const newUrl = router.resolve(to).href;
        if (router.isPopStateDetected()) window.history.replaceState({
            ...window.history.state,
            current: to.fullPath,
            forward: window.history.state?.forward || lastState?.current
        }, '', newUrl);
        else processNotPopstateState(to, router, lastState);
    }
}
