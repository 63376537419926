import { ref } from 'vue';
import { defineStore } from 'pinia';
/**
 * @hint It shouldn't use any module directly. It just stores getters for navigation
 * Unfortunately we can't directly use router here
 * because we have to init store before router is created
 */ export const useNavigationBackButtonStore = defineStore('navigation-back-button-store', ()=>{
    let navigationBackLocationGetter = null;
    const isBackButtonAvailable = ref(false);
    function setNavigationBackLocationGetter(value) {
        navigationBackLocationGetter = value;
        isBackButtonAvailable.value = !!value;
    }
    function getBackLocation() {
        return navigationBackLocationGetter?.();
    }
    return {
        isBackButtonAvailable,
        getBackLocation,
        setNavigationBackLocationGetter
    };
});
