import { isFunction } from '@leon-hub/guards';
import { AbstractPageSeoController } from 'web/src/modules/core/services/router/seo';
import { importComponent } from 'web/src/modules/core/utils';
function isPrefetchFunction(value) {
    return isFunction(value);
}
const defaultSeoController = ()=>import('web/src/modules/seo/DefaultPageSeoController');
async function loadSeoRouteComponents(to) {
    const importSeoController = to.meta?.seoController || defaultSeoController;
    isPrefetchFunction(importSeoController);
    const SeoControllerClass = await importComponent(importSeoController);
    const seoController = new SeoControllerClass();
    if (!(seoController instanceof AbstractPageSeoController)) throw new Error('Imported seo controller is not of AbstractPageSeoController class');
    return seoController;
}
export function createSeoMetaDataNavigationGuard(router) {
    router.beforeEach((to, from, next)=>{
        loadSeoRouteComponents(to).then(async (seoController)=>{
            const config = await seoController.getSeoConfig(router, from, to);
            seoController.loadSeoMetaDataFor(config);
        });
        next();
    });
}
