import { toRef } from 'vue';
import { AbstractError, normalizeError } from '@leon-hub/errors';
import { ApiConnectionError, GqlApiAccessDeniedError, ApiServiceUnavailableError } from '@leon-hub/api';
import { hashString } from '@leon-hub/utils/src/hash/murmurHash';
import { IconName } from '@leon-hub/icons';
import { TargetParamEnter } from '@leon-hub/yandex-metrika/src/constants/targetParams';
import RouteName from '@leon-hub/routing-config-names';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { ModalWidth } from '@leon-hub/module-modal';
import { isOpenSearchLoggableError, isSentryLoggableError } from '@leon-hub/errors-whitelist';
import { Sentry } from '@leon-hub/sentry';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { AccessDeniedModalId } from 'web/src/modules/dialogs/consts';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { getErrorCode, getModalId, isMutationRequestError, isSilentError, logError } from './utils';
import { whiteListErrorCodes, accessDeniedErrorCode } from './constants';
function generateDialogOptions(error, $translate) {
    let options = {
        confirmMessage: error.message
    };
    if (error instanceof GqlApiAccessDeniedError) options = {
        iconName: IconName.CLOCK,
        confirmMessage: $translate('WEB2_ACCESS_DENIED_ERROR_DESCRIPTION').value,
        title: $translate('WEB2_ACCESS_DENIED_ERROR_TITLE').value,
        buttons: [
            {
                label: $translate('WEB2_ACCESS_DENIED_ERROR_BUTTON').value
            }
        ],
        width: ModalWidth.MEDIUM,
        image: void 0
    };
    else if (error instanceof ApiConnectionError) options.confirmMessage = $translate('CONNECTION_ERROR').value;
    return options;
}
export default function useErrorHandler(router) {
    const { showDialog } = useDialogs();
    const analytics = useAnalytics();
    const { $translate } = useI18n();
    const { isLoggedIn } = useIsLoggedIn();
    const authStore = useAuthStore();
    const isTemporarySession = toRef(authStore, 'isTemporarySession');
    const isSolidAuth = toRef(()=>isLoggedIn.value && !isTemporarySession.value);
    function showErrorDialog(error, options) {
        const modalId = getModalId(error, options);
        const errorCode = getErrorCode(error);
        showDialog({
            id: modalId,
            presetName: PresetName.ALERT_ERROR,
            options
        }).subscribe({
            [DialogAction.MODAL_CLOSE]: ()=>{
                if (errorCode === accessDeniedErrorCode) {
                    analytics.push(AnalyticsEvent.CLICK_MAP, {
                        clickCounter: {
                            enter: TargetParamEnter.SESSION_EXPIRED
                        }
                    });
                    router.push({
                        name: RouteName.LOGIN
                    });
                }
            }
        });
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function showError(error) {
        const errorCode = getErrorCode(error);
        const options = generateDialogOptions(error, $translate);
        const modalId = error instanceof GqlApiAccessDeniedError ? AccessDeniedModalId : hashString(`GlobalError${errorCode}-${options.confirmMessage}`);
        if (error instanceof GqlApiAccessDeniedError) {
            options.iconName = IconName.CLOCK;
            options.confirmMessage = $translate('WEB2_ACCESS_DENIED_ERROR_DESCRIPTION').value;
            options.title = $translate('WEB2_ACCESS_DENIED_ERROR_TITLE').value;
            options.buttons = [
                {
                    label: $translate('WEB2_ACCESS_DENIED_ERROR_BUTTON').value
                }
            ];
            options.width = ModalWidth.MEDIUM;
            options.image = void 0;
        }
        if (error instanceof ApiConnectionError) options.confirmMessage = $translate('CONNECTION_ERROR').value;
        options.dataTestId = modalId;
        showErrorDialog(error, options);
    }
    function handleError(rawErr) {
        const error = rawErr ? useErrorsConverter().convertToBaseError(normalizeError(rawErr)) : new Error(`Unable to handle error: ${rawErr}`);
        if (error instanceof GqlApiAccessDeniedError) {
            if (!isSolidAuth.value) return;
            error.silent = false;
            // always display "Session expired" error
            authStore.forgotSession();
        }
        if (!isSilentError(error)) handleNonSilentError(error);
        log(error);
    }
    function handleNonSilentError(error) {
        if (error instanceof ApiServiceUnavailableError && !isMutationRequestError(error)) {
            router.push({
                name: RouteName.ERROR_SERVICE_UNAVAILABLE_503
            });
            return;
        }
        if (error.message && !whiteListErrorCodes.includes(getErrorCode(error))) showError(error);
    }
    function log(error) {
        if (isOpenSearchLoggableError(error)) logError(error);
        if (isSentryLoggableError(error)) Sentry.captureException(error, {
            level: 'error',
            tags: {
                errorCode: error instanceof AbstractError ? error.code.toString() : 'UNKNOWN'
            }
        });
    }
    return {
        handleError
    };
}
