import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export default function createTFARoutes(router) {
    router.addModalRoute({
        name: RouteName.TWO_FACTOR_AUTHENTICATION_LOGIN,
        path: '/login/two-factor-authentication',
        modalPreset: ModalDesktopPreset.SmallDesktopModal,
        title: '{{$t(\'JS_MOBILE_SETTINGS_TFAUTH_TITLE\')}}',
        component: ()=>import('web/src/modules/two-factor-authentication/pages/TfaLoginRoutePage/TfaLoginRoutePage.vue'),
        access: CustomerRouteAccessRole.ANONYMOUS
    });
    router.addProfileRoute({
        name: RouteName.TWO_FACTOR_AUTHENTICATION,
        path: '/profile/settings/two-factor-authentication',
        prefetch: ()=>import('web/src/modules/two-factor-authentication/prefetch/TfaPagePrefetch'),
        component: ()=>import('web/src/modules/two-factor-authentication/pages/TfaRoutePage/TfaRoutePage.vue'),
        topBar: ()=>import('web/src/modules/two-factor-authentication/layouts/TfaRoutePageTopBar.vue'),
        title: '{{$t(\'JS_MOBILE_SETTINGS_TFAUTH_TITLE\')}}'
    });
    router.addProfileRoute({
        name: RouteName.TWO_FACTOR_AUTHENTICATION_REGENERATE,
        path: '/profile/settings/two-factor-authentication/regenerate',
        prefetch: ()=>import('web/src/modules/two-factor-authentication/prefetch/TfaPagePrefetch'),
        component: ()=>import('web/src/modules/two-factor-authentication/pages/TfaRegenerateRoutePage/TfaRegenerateRoutePage.vue'),
        title: '{{$t(\'WEB2_G2SV_REGENERATE_TITLE\')}}'
    });
}
