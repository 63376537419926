import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import { useTheHeader } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheHeader',
    setup (__props) {
        const content = ref();
        const { headerStyles, isCalculated } = useTheHeader(content);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("header", {
                class: _normalizeClass(_ctx.$style['header']),
                role: "banner",
                style: _normalizeStyle(_unref(headerStyles))
            }, [
                _createElementVNode("div", {
                    ref_key: "content",
                    ref: content,
                    class: _normalizeClass({
                        [_ctx.$style['header__content']]: true,
                        [_ctx.$style['header__content--calculated']]: _unref(isCalculated)
                    })
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 2)
            ], 6)), [
                [
                    _directive_auto_id,
                    'TheHeader'
                ]
            ]);
        };
    }
});
