import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default function useDowntimeConfig() {
    const siteConfigStore = useSiteConfigStore();
    const downtime = toRef(siteConfigStore, 'downtimeBlock');
    return {
        enabled: computed(()=>!!downtime.value?.enabled),
        interval: computed(()=>downtime.value?.interval || {
                from: 0,
                to: 0,
                shown: false
            }),
        visibleBeforeHours: computed(()=>downtime.value?.visibleBeforeHours || 0)
    };
}
