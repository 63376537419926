import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
export default function createSnackBarNavigationGuard(router) {
    const { setConnectionLostRoute } = useSnackbarsStore();
    router.beforeEach((to)=>{
        setConnectionLostRoute(to.fullPath);
    });
    router.afterEach(()=>{
        setConnectionLostRoute('');
    });
}
