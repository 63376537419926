import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
/* eslint-disable @typescript-eslint/no-shadow */ import debug from 'debug';
import { Memoized } from '@leon-hub/utils';
import { DEBUG_KEY, isDebugEnabled } from '@leon-hub/debug';
import { isFunction } from '@leon-hub/guards';
import DebugError from './DebugError';
import BaseDebugLogger from './BaseDebugLogger';
const baseDebugLogger = new BaseDebugLogger();
// Do not export.
const key = Symbol('Private key');
let DefaultDebugLogger = class DefaultDebugLogger {
    evaluateInstance() {
        return this.instance ? this.instance() : void 0;
    }
    static create() {
        let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const { instance, namespace = 'app', debugEnabledKey } = options;
        let debugLogger = baseDebugLogger;
        try {
            debugLogger = new DefaultDebugLogger({
                key,
                debugEnabledKey: debugEnabledKey || DEBUG_KEY.GENERAL,
                instance: instance || (()=>{
                    if (!isDebugEnabled(debugEnabledKey || DEBUG_KEY.GENERAL)) return;
                    const newInstance = debug(namespace);
                    newInstance.enabled = true;
                    return newInstance;
                })
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Unable to create debug logger instance', error);
        }
        return debugLogger;
    }
    /**
   * @param {Array} rest parameters to log, fn will return value instead of fn serialization.
   */ log() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
        if (!isDebugEnabled(this.debugEnabledKey)) return;
        if (this.instance) {
            const instance = this.evaluateInstance();
            if (instance) Reflect.apply(instance, void 0, rest.map((item)=>'function' == typeof item ? item() : item));
        }
    }
    extend(namespace) {
        if (isDebugEnabled(this.debugEnabledKey)) return this.extendMemoized(namespace, this);
        return baseDebugLogger;
    }
    extendMemoized(namespace, parent) {
        return new DefaultDebugLogger({
            key,
            instance: ()=>this.instance?.()?.extend(namespace),
            parent
        });
    }
    constructor({ instance, parent, namespace = '', key, debugEnabledKey } = {}){
        _define_property(this, "instance", void 0);
        _define_property(this, "parent", void 0);
        _define_property(this, "namespace", void 0);
        _define_property(this, "debugEnabledKey", DEBUG_KEY.GENERAL);
        if (!key) throw new DebugError({
            message: 'Create new instance with static method "create"'
        });
        if (isFunction(instance)) this.instance = instance;
        this.parent = parent;
        this.namespace = namespace;
        this.debugEnabledKey = debugEnabledKey || DEBUG_KEY.GENERAL;
    }
};
export { DefaultDebugLogger as default };
_ts_decorate([
    Memoized(),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", Object)
], DefaultDebugLogger.prototype, "evaluateInstance", null);
_ts_decorate([
    Memoized({
        shared: true
    }),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", [
        void 0
    ]),
    _ts_metadata("design:returntype", void 0 === BaseDebugLogger ? Object : BaseDebugLogger)
], DefaultDebugLogger, "create", null);
_ts_decorate([
    Memoized({
        shared: true
    }),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", [
        String,
        Object
    ]),
    _ts_metadata("design:returntype", void 0)
], DefaultDebugLogger.prototype, "extendMemoized", null);
