import { safeCall } from '@leon-hub/utils';
export function watchFetch() {
    let { onBefore, onAfter } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        onBefore: ()=>void 0,
        onAfter: ()=>void 0
    };
    let isWatched = true;
    const prevFetch = window.fetch;
    window.fetch = async function wrappedFetch() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
        if (isWatched) safeCall(onBefore);
        try {
            return await Reflect.apply(prevFetch, this, rest);
        } finally{
            if (isWatched) safeCall(onAfter);
        }
    };
    return ()=>{
        isWatched = false;
        window.fetch = prevFetch;
    };
}
