import { AbstractError } from '@leon-hub/errors';
import { isError } from '@leon-hub/guards';
import { AppErrorCode } from './AppErrorCode';
export class UnhandledRejection extends AbstractError {
    constructor({ event, ...options }){
        const { reason } = event;
        super({
            ...options,
            code: new AppErrorCode('UNHANDLED_REJECTION'),
            interactive: event.isTrusted,
            originalError: isError(reason) ? reason : void 0
        });
        this.addLogMetaData('eventTimeStamp', event.timeStamp);
        if ('undefined' != typeof window) this.addLogMetaData('isRootTarget', event.target === window.event?.target);
        if (!isError(reason)) this.addLogMetaData('reason', reason);
    }
}
