import { toRef, computed } from 'vue';
import useModuleTimersStore from 'web/src/modules/core/store/useModuleTimersStore';
export function useModuleTimeout(timerName, timerFallback) {
    const moduleTimersStore = useModuleTimersStore();
    const defaultTimeout = toRef(moduleTimersStore, 'defaultTimeout');
    const moduleTimeouts = toRef(moduleTimersStore, 'moduleTimeouts');
    const timeoutSettings = computed(()=>timerName ? moduleTimeouts.value[timerName] : void 0);
    const timeout = computed(()=>timeoutSettings.value ?? timerFallback?.value ?? defaultTimeout.value);
    return {
        timeout
    };
}
