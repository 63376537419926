import { AbstractError } from '@leon-hub/errors';
import defaultDebugErrorCode from './DebugErrorCode';
let DebugError = class DebugError extends AbstractError {
    constructor(options){
        super({
            ...options,
            code: defaultDebugErrorCode
        });
    }
};
export { DebugError as default };
