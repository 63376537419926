import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import VDynamicContent from 'web/src/components/DynamicContent/VDynamicContent/VDynamicContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DesktopTitle',
    props: {
        title: {
            default: ''
        },
        isBig: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['desktop-title'])
            }, [
                _renderSlot(_ctx.$slots, "default", {}, ()=>[
                        _createVNode(VDynamicContent, {
                            tag: _unref(Tag).H1,
                            content: _ctx.title,
                            class: _normalizeClass({
                                [_ctx.$style['desktop-title__title']]: true,
                                [_ctx.$style['desktop-title__title--big']]: _ctx.isBig
                            })
                        }, null, 8, [
                            "tag",
                            "content",
                            "class"
                        ])
                    ]),
                _createElementVNode("div", null, [
                    _renderSlot(_ctx.$slots, "suffix")
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'DesktopTitle'
                ]
            ]);
        };
    }
});
