import { useInitialRequests } from 'web/src/modules/core/components/composables';
export default function useGuestHandler() {
    const { getInitRequests } = useInitialRequests();
    async function onLogout(isInit) {
        const requests = [];
        if (isInit) requests.push(...getInitRequests());
        await Promise.all(requests);
    }
    return {
        onLogout
    };
}
