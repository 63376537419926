import RouteName from '@leon-hub/routing-config';
export default function createResponsibleGamblingRoutes(router) {
    router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_DEPOSIT_LIMITS,
        path: '/profile/settings/responsible-gambling-deposit-limits',
        component: ()=>import('web/src/modules/responsible-gambling/pages/ResponsibleGamblingDepositLimitsRoutePage/ResponsibleGamblingDepositLimitsRoutePage.vue'),
        title: '{{$t(\'WEB2_RESTRICT_ACCOUNT_DEPOSIT\')}}'
    });
    router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_BETTING_LIMITS,
        path: '/profile/settings/responsible-gambling-betting-limits',
        component: ()=>import('web/src/modules/responsible-gambling/pages/ResponsibleGamblingBettingLimitsRoutePage/ResponsibleGamblingBettingLimitsRoutePage.vue'),
        title: '{{$t(\'WEB2_LIMIT_AMOUNT_OF_BETS\')}}'
    });
    router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_SELF_EXCLUSION,
        path: '/profile/settings/responsible-gambling-self-exclusion',
        component: ()=>import('web/src/modules/responsible-gambling/pages/ResponsibleGamblingSelfExclusionRoutePage/ResponsibleGamblingSelfExclusionRoutePage.vue'),
        title: '{{$t(\'WEB2_SELFEXCLUSION_FOR_SIX_MONTH\')}}'
    });
    router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_COMPREHENSIVE_SELF_EXCLUSION,
        path: '/profile/settings/responsible-gambling-comprehensive-self-exclusion',
        // eslint-disable-next-line max-len
        component: ()=>import('web/src/modules/responsible-gambling/pages/ResponsibleGamblingComprehensiveSelfExclusionRoutePage/ResponsibleGamblingComprehensiveSelfExclusionRoutePage.vue'),
        title: '{{$t(\'WEB2_COMPLEX_SELFEXCLUSION\')}}'
    });
    router.addProfileRoute({
        name: RouteName.RESPONSIBLE_GAMBLING_PARTIAL_RESTRICTION,
        path: '/profile/settings/responsible-gambling-partial-restriction',
        component: ()=>import('web/src/modules/responsible-gambling/pages/ResponsibleGamblingPartialRestrictionRoutePage/ResponsibleGamblingPartialRestrictionRoutePage.vue'),
        title: '{{$t(\'WEB2_PARTIAL_RESTRICTION_SMALL\')}}'
    });
}
