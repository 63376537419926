import { toRef } from 'vue';
import { useCustomerBalanceUpdateStore } from 'web/src/modules/customer/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
import { useInitialRequests } from 'web/src/modules/core/components/composables';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { useFortuneWheelStore } from 'web/src/modules/fortune-wheel/store';
import useResponsibleGamblingStoreV2 from 'web/src/modules/profile/submodules/responsible-gambling/store/useResponsibleGamblingStoreV2';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useReferralProgramStore from 'web/src/modules/referral-program/store/useReferralProgramStore';
import { getEgsLoyaltyStore } from './utils';
export default function useAuthorizedHandler() {
    const egsLoyaltyStore = getEgsLoyaltyStore();
    const frontNotificationsStore = useFrontNotificationsStore();
    const referralProgramStore = useReferralProgramStore();
    const bonusStore = useBonusStore();
    const siteConfigStore = useSiteConfigStore();
    const { loadData: loadBalance } = useCustomerBalanceUpdateStore();
    const { loadUserData } = useUserStore();
    const { getInitRequests } = useInitialRequests();
    const { init: initResponsibleGambling } = useResponsibleGamblingStoreV2();
    const isProfileReferralProgramEnabled = toRef(()=>siteConfigStore.isProfileReferralProgramEnabled);
    async function onLogin(isInit) {
        const requests = [];
        if (isProfileReferralProgramEnabled.value) await referralProgramStore.getAvailability();
        if (isInit) requests.push(loadUserData(), ...getInitRequests());
        const { fetchActiveOrAvailableCampaigns } = useFortuneWheelStore();
        requests.push(frontNotificationsStore.getUnreadNotifications(true), bonusStore.getBonusWalletTransferAmount(), bonusStore.fetchBonusWagers(), loadBalance(), fetchActiveOrAvailableCampaigns(), initResponsibleGambling());
        "1";
        requests.push(egsLoyaltyStore.fetchCasinoLoyaltyStatus());
        await Promise.all(requests);
    }
    return {
        onLogin
    };
}
