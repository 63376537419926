/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ import { createRouter, createWebHistory } from 'vue-router';
import { useRouterStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { getBaseUrl } from 'web/src/modules/core/utils';
import { create301RedirectNavigationGuard, createQueryNavigationGuard, createAccessRestrictionsNavigationGuard, createRegistrationNavigationGuard, checkLanguagePrefix, createDesktopModalNavigationGuard, createBaseNavigationGuard, createSnackBarNavigationGuard, createThemeNavigationGuard, createSeoMetaDataNavigationGuard, createHeaderBackButtonNavigationGuard } from './navigation-guards';
import createRoutes from './config/createRoutes';
import getAppVueRouter from './getAppVueRouter';
import ModalOpenedRouteError from './errors/ModalOpenedRouteError';
import { syncAuthStateToRoute } from './utils';
export default function createAppRouter() {
    window.history.replaceState(null, '');
    const i18nStore = useI18nStore();
    const { replacedUrlPrefix, currentUrlPrefix } = i18nStore;
    const base = getBaseUrl(currentUrlPrefix) || "/";
    const redirectUri = checkLanguagePrefix({
        replacedUrlPrefix,
        currentUrlPrefix
    });
    const router = getAppVueRouter(createRouter({
        history: createWebHistory(base),
        routes: []
    }));
    const { seoConfigs } = useRouterStore();
    createRoutes(router, {
        seoConfigs
    });
    syncAuthStateToRoute(router);
    router.onError((error)=>{
        if (!(error instanceof ModalOpenedRouteError)) throw error;
        return false;
    });
    create301RedirectNavigationGuard(router, {
        redirectUri
    });
    createSnackBarNavigationGuard(router);
    createThemeNavigationGuard(router);
    createHeaderBackButtonNavigationGuard(router);
    createQueryNavigationGuard(router);
    createAccessRestrictionsNavigationGuard(router);
    "1";
    createRegistrationNavigationGuard(router);
    "1";
    createDesktopModalNavigationGuard(router);
    createSeoMetaDataNavigationGuard(router);
    createBaseNavigationGuard(router);
    return router;
}
