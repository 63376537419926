import { getLocationPath, getLocationSearch } from '@leon-hub/service-locator-env';
function preparePathname(pathname, currentUrlPrefix) {
    let newPathname = pathname.startsWith('/') ? pathname : `/${pathname}`;
    if (newPathname && '/' !== newPathname && newPathname === `/${currentUrlPrefix}`) newPathname += '/';
    if (newPathname !== `/${currentUrlPrefix}/` && newPathname.endsWith('/')) newPathname = newPathname.slice(0, -1);
    return newPathname || '/';
}
export default function getNewUri(replacedUrlPrefix, currentUrlPrefix) {
    const pathname = getLocationPath();
    const search = getLocationSearch();
    if (replacedUrlPrefix === currentUrlPrefix) return `${preparePathname(pathname, replacedUrlPrefix)}${search}`;
    const pathnameToSplit = pathname.startsWith('/') ? pathname.substring(1) : pathname;
    const pathnameParts = pathnameToSplit ? pathnameToSplit.split('/') : [];
    if (replacedUrlPrefix && replacedUrlPrefix === pathnameParts[0]) pathnameParts.shift();
    if (currentUrlPrefix) pathnameParts.unshift(currentUrlPrefix);
    const newPathname = preparePathname(pathnameParts.join('/'), currentUrlPrefix);
    return `${newPathname}${search}`;
}
