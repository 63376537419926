import useSeoMetaProperties from 'web/src/modules/seo/composables/useSeoMetaProperties';
export default function initMetaInfoInterface(router) {
    const { metaTitle, metaTags, metaScripts, metaParameters, spintaxMetaInfo, spintaxMetaData } = useSeoMetaProperties({
        router
    });
    window.mainApp = window.mainApp || {};
    Object.defineProperty(window.mainApp, 'metaInfo', {
        set () {
            throw new Error('Cannot redefine readonly property "metaInfo".');
        },
        get () {
            return Object.freeze({
                componentsMetaParameters: metaParameters.value,
                title: metaTitle.value,
                tags: metaTags.value,
                scripts: metaScripts.value,
                spintaxMetaInfo: spintaxMetaInfo.value,
                spintaxConfig: spintaxMetaData.value
            });
        }
    });
}
