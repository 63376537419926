import { ref, shallowRef, watch } from 'vue';
import { importComponent } from 'web/src/modules/core/utils';
import { AccessDeniedModalId } from 'web/src/modules/dialogs/consts';
import { useDialogsStore } from 'web/src/modules/dialogs/store';
import useDesktopModal from './useDesktopModal';
export default function useDesktopModalComponents() {
    const dialogsStore = useDialogsStore();
    const { desktopModal } = useDesktopModal();
    const DefaultComponent = shallowRef(null);
    const defaultProps = ref({});
    const TopBar = shallowRef(null);
    const topBarProps = ref({});
    const Navigation = shallowRef(null);
    watch(desktopModal, (newValue)=>{
        // TODO: move to module after modals to pinia
        if (!newValue && dialogsStore.currentDialog && dialogsStore.currentDialog.dialog.id !== AccessDeniedModalId) dialogsStore.closeDialog(dialogsStore.currentDialog.dialog.id);
        if (newValue) {
            importComponent(newValue.default.component).then((result)=>{
                DefaultComponent.value = result;
                defaultProps.value = true === newValue.default.props ? newValue.route.params : newValue.default.props ?? {};
            });
            importComponent(newValue.topBar.component).then((result)=>{
                TopBar.value = result;
                topBarProps.value = true === newValue.topBar.props ? newValue.route.params : newValue.topBar.props ?? {};
            });
            if (newValue.navigation) importComponent(newValue.navigation.component).then((result)=>{
                Navigation.value = result;
            });
            else Navigation.value = null;
        } else {
            DefaultComponent.value = null;
            TopBar.value = null;
            Navigation.value = null;
            topBarProps.value = {};
            defaultProps.value = {};
        }
    });
    return {
        DefaultComponent,
        defaultProps,
        TopBar,
        topBarProps,
        Navigation
    };
}
