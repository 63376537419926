import RouteName from '@leon-hub/routing-config';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
export default function createVerifyEmailRoutes(router) {
    router.addModalRoute({
        name: RouteName.VERIFY_EMAIL_STATUS_PAGE,
        path: '/verify-email',
        modalPreset: ModalDesktopPreset.SmallDesktopModal,
        component: ()=>import('web/src/modules/verify-email/views/VerifyEmailRouteComponent')
    });
    router.addModalRoute({
        name: RouteName.VERIFY_EMAIL,
        path: '/verify-email/:token',
        modalPreset: ModalDesktopPreset.SmallDesktopModal,
        component: ()=>import('web/src/modules/verify-email/views/VerifyEmailRouteComponent')
    });
}
