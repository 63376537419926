import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';
export default function setupAppBusEvents(router) {
    const bus = useEventsBus();
    const { handleError } = useErrorHandler(router);
    // without bus.off(BusEvent.REDIRECT)
    bus.on(BusEvent.REDIRECT, (param)=>{
        let { to, replace } = param;
        if (replace) router.replace(to);
        else router.push(to);
    });
    // without bus.off(BusEvent.API_ERROR)
    bus.on(BusEvent.API_ERROR, (param)=>{
        let { error } = param;
        handleError(error);
    });
}
