import RouteName from '@leon-hub/routing-config';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useModulesSyncStore } from 'web/src/modules/core/store';
import initAppRouterInterface from './initAppRouterInterface';
import initMetaInfoInterface from './initMetaInfoInterface';
import initErrorsInterface from './initErrorsInterface';
import initCustomerAppInterface from './initCustomerAppInterface';
export default function initPublicAppInterface(router) {
    if (process.env.VUE_APP_PRERENDER) return;
    // public block
    window.mainApp = window.mainApp || {};
    if (useIsDevIP().isDevIP.value) {
        const bus = useEventsBus();
        // tech block
        window.mainApp.isDevIP = true;
        initMetaInfoInterface(router);
        initCustomerAppInterface();
        initErrorsInterface(bus);
        initAppRouterInterface(router);
        const { syncState } = useModulesSyncStore();
        window.mainApp.syncState = syncState;
        window.mainApp.scrollbarScrollTo = function scrollbarScrollTo(scrollbar, element, options) {
            bus.emit(BusEvent.QA_SCROLLBAR_SCROLL_TO, {
                scrollbar,
                el: element,
                options
            });
        };
        Object.defineProperty(window.mainApp, 'routeName', {
            set () {
                throw new Error('Cannot redefine readonly property "routeName". Use configuration for change app navigation');
            },
            get () {
                return Object.freeze({
                    ...RouteName,
                    ...CasinoRouteName
                });
            }
        });
    }
}
