import { defineStore } from 'pinia';
import { ref } from 'vue';
import { isTheme } from '@leon-hub/api-guards';
import { isEmpty } from '@leon-hub/guards';
import { Theme } from '@leon-hub/api-sdk/src/sdk/sdk';
import { getBuiltinConfig } from '@leon-hub/service-locator-env';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { StorageKeys } from './enums';
// eslint-disable-next-line sonarjs/cognitive-complexity
const useInitAppConfigStore = defineStore('init-app-config', ()=>{
    const themeConfig = ref({
        default: Theme.DARK,
        isAuto: false
    });
    const localStorageManager = useLocalStorageManager();
    {
        const initConfig = getBuiltinConfig();
        const defaultTheme = initConfig?.theme.default.toUpperCase();
        if (isTheme(defaultTheme)) themeConfig.value.default = defaultTheme;
        themeConfig.value.first = initConfig?.theme.first?.toUpperCase() || localStorageManager.getItem(StorageKeys.FIRST_THEME)?.toUpperCase();
        themeConfig.value.current = localStorageManager.getItem(StorageKeys.THEME) || initConfig?.theme.current?.toUpperCase() || themeConfig.value.default;
        themeConfig.value.isAuto = !isEmpty(localStorageManager.getItem(StorageKeys.IS_AUTO_THEME)) && 'true' === localStorageManager.getItem(StorageKeys.IS_AUTO_THEME);
    }
    return {
        themeConfig
    };
});
export default useInitAppConfigStore;
