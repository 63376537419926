export { default as LayoutSizes } from './LayoutSizes';
export var ModalDesktopPreset;
var ModalDesktopPreset1;
// eslint-disable-next-line import/prefer-default-export
(ModalDesktopPreset1 = ModalDesktopPreset || (ModalDesktopPreset = {}))["SmallDesktopModal"] = "SmallDesktopModal", ModalDesktopPreset1["DepositDetailsDesktopModal"] = "DepositDetailsDesktopModal", ModalDesktopPreset1["PrivacyPolicyModal"] = "PrivacyPolicyModal", ModalDesktopPreset1["LargeDesktopModal"] = "LargeDesktopModal", ModalDesktopPreset1["SearchDesktopModal"] = "SearchDesktopModal", ModalDesktopPreset1["ProviderSlotDesktopModal"] = "ProviderSlotDesktopModal", ModalDesktopPreset1["ProfileDesktopModal"] = "ProfileDesktopModal", ModalDesktopPreset1["EuroBattlePassModal"] = "EuroBattlePassModal";
export var ModalSelector;
var ModalSelector1;
(ModalSelector1 = ModalSelector || (ModalSelector = {}))["BODY"] = "body", ModalSelector1["DESKTOP_INNER_MODAL"] = "#desktop-modal", ModalSelector1["APP"] = "#app";
export var FeedbackApiErrorCode;
(FeedbackApiErrorCode || (FeedbackApiErrorCode = {}))["EMAIL_ALREADY_EXISTS"] = "EMAIL_ALREADY_EXISTS";
