import RouteName from '@leon-hub/routing-config';
export default function create301RedirectNavigationGuard(router, param) {
    let { redirectUri } = param;
    if (process.env.VUE_APP_PRERENDER && redirectUri) {
        let localRedirectUri = redirectUri;
        router.beforeEach((to, from, next)=>{
            if (localRedirectUri) {
                next({
                    name: RouteName.REDIRECT_SEO,
                    state: {
                        redirectCode: '301',
                        redirectLocation: redirectUri
                    }
                });
                localRedirectUri = '';
                return;
            }
            next();
        });
    }
}
