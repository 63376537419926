import { ref, watch, toRef, computed } from 'vue';
import { Timer } from '@leon-hub/utils';
import { isString } from '@leon-hub/guards';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useModulesSyncStore } from 'web/src/modules/core/store/useModulesSyncStore';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useModuleTimeout } from './useModuleTimeout';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useSyncState(callback, timerName) {
    let { suspendedByDefault, disableLoginLogoutHandlers, condition } = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    if (!timerName) throw new Error('Please provide timerName to use syncState');
    const { timeout } = isString(timerName) ? useModuleTimeout(timerName) : useModuleTimeout(null, timerName);
    const isStopped = ref(suspendedByDefault ?? false);
    const syncStateCounter = toRef(useModulesSyncStore(), 'syncStateCounter');
    const conditionValue = condition ?? ref(true);
    const isSyncAvailable = computed(()=>!isStopped.value && conditionValue.value && timeout.value > 0);
    let timerId = 0;
    function stopSyncState() {
        if (timerId) {
            Timer.clearTimeout(timerId);
            timerId = 0;
        }
    }
    function startSyncState() {
        stopSyncState();
        if (isSyncAvailable.value) timerId = Timer.setTimeout(()=>{
            callback(true).finally(()=>{
                startSyncState();
            });
        }, timeout.value);
    }
    function pauseSyncState() {
        if (!isStopped.value) {
            isStopped.value = true;
            stopSyncState();
        }
    }
    function resumeSyncState() {
        if (isStopped.value) {
            isStopped.value = false;
            startSyncState();
        }
    }
    watch(timeout, startSyncState);
    watch(isSyncAvailable, (newValue)=>{
        if (newValue) startSyncState();
        else stopSyncState();
    }, {
        immediate: true
    });
    watch(syncStateCounter, ()=>{
        callback(true);
    });
    if (!disableLoginLogoutHandlers) {
        const authStore = useAuthStore();
        const isLoadedFromRoot = toRef(()=>authStore.isLoadedFromRoot);
        const { isLoggedIn } = useIsLoggedIn();
        watch(isLoggedIn, ()=>{
            if (isLoadedFromRoot.value) // prevent double requests on init for auth customer
            callback();
        });
    }
    return {
        timeout,
        pauseSyncState,
        resumeSyncState
    };
}
