import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import RouteName from '@leon-hub/routing-config-names';
import { useRouterStore } from 'web/src/modules/core/store';
export default function performLogin(router) {
    const { currentRoute, getAuthorizedRouteHistoryDelta } = useRouterStore();
    if (currentRoute?.meta.saveRouteOnLogin) return;
    if (window.history.state?.toRoute) {
        router.replace(window.history.state.toRoute);
        return;
    }
    if (currentRoute?.meta.access === CustomerRouteAccessRole.ANONYMOUS) {
        const delta = getAuthorizedRouteHistoryDelta();
        if (0 !== delta) router.go(delta);
        else router.replace({
            name: RouteName.HOME
        });
    }
}
