import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
import { ref } from 'vue';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { IconName, IconSize } from '@leon-hub/icons';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { useWindowResize } from '@leon-hub/browser-composables';
import { VScrollbar } from '@components/v-scrollbar';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { LayoutSizes } from 'web/src/modules/core/enums';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LayoutDesktopLLeftSidebar',
    setup (__props) {
        const isLeftSideBarOpen = ref(false);
        function toggleMenu() {
            isLeftSideBarOpen.value = !isLeftSideBarOpen.value;
        }
        function setLeftSidebarClosed() {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            if (document.documentElement.clientWidth > LayoutSizes.ShowLeftSidebarBreakpoint) isLeftSideBarOpen.value = false;
        }
        useBusSafeSubscribe(BusEvent.SIDE_MENU_SOFT_CLOSE, setLeftSidebarClosed);
        useBusSafeSubscribe(BusEvent.SIDE_MENU_TOGGLE, toggleMenu);
        useWindowResize(setLeftSidebarClosed);
        return (_ctx, _cache)=>{
            const _component_router_view = _resolveComponent("router-view");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("aside", {
                "aria-label": "left-sidebar",
                class: _normalizeClass({
                    [_ctx.$style['column__left']]: true,
                    [_ctx.$style['column__left--open']]: isLeftSideBarOpen.value
                })
            }, [
                isLeftSideBarOpen.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['column__left-close'])
                }, [
                    _withDirectives(_createVNode(VButton, {
                        class: _normalizeClass(_ctx.$style['column__close-button']),
                        height: _unref(ButtonHeight).MEDIUM,
                        "icon-name": _unref(IconName).CROSS,
                        kind: _unref(ButtonKind).TRANSPARENT,
                        "icon-size": _unref(IconSize).SIZE_20,
                        onClick: toggleMenu
                    }, null, 8, [
                        "class",
                        "height",
                        "icon-name",
                        "kind",
                        "icon-size"
                    ]), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'left-sidebar-close-btn'
                            }
                        ]
                    ])
                ], 2)) : _createCommentVNode("", true),
                _createVNode(_unref(VScrollbar), {
                    "test-el": "left-side-bar",
                    "flex-fill": ""
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['left-side-bar__content'])
                            }, [
                                _createVNode(_component_router_view, {
                                    name: "leftSideBar"
                                })
                            ], 2)
                        ]),
                    _: 1
                }),
                (_openBlock(), _createBlock(_Teleport, {
                    to: "body"
                }, [
                    _createVNode(_Transition, {
                        name: "opacity"
                    }, {
                        default: _withCtx(()=>[
                                isLeftSideBarOpen.value ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['column__main-mask']),
                                    onClick: toggleMenu
                                }, null, 2)) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    })
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LayoutDesktopLLeftSidebar'
                ],
                [
                    _unref(vDataTestUnit),
                    {
                        el: 'left-sidebar'
                    }
                ]
            ]);
        };
    }
});
