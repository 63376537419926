import RouteName from '@leon-hub/routing-config';
import { CustomerCompletionAction } from 'web/src/modules/registration-completion/enums';
import { SimpleRegCompletionRouteParametersHandler } from './utils';
export default function registrationCompletionGuard(param) {
    let { isLoggedIn, isSimpleRegistrant, isRegistrationFinish, isExAllowedCountry, isCountryConfirmed, isForbiddenCountry, isAsoTermsConfirmationNeeded, to } = param;
    if (to.name === RouteName.HOME || to.name === RouteName.REGISTRATION_COMPLETION || to.name === RouteName.LOGIN || /^\/registration(\/?)(.*)?$/i.test(to.fullPath) && to.name !== RouteName.REGISTRATION_COMPLETION || !isLoggedIn) return;
    if (!isSimpleRegistrant || isRegistrationFinish && !isExAllowedCountry) return;
    if (!isCountryConfirmed || !isExAllowedCountry && isForbiddenCountry) {
        SimpleRegCompletionRouteParametersHandler.setRedirectLocation(to);
        return {
            name: RouteName.REGISTRATION_COMPLETION,
            params: {
                customerAction: CustomerCompletionAction.ForbiddenCountry
            }
        };
    }
}
