import { nextTick, ref } from 'vue';
import { nextAnimationFrame } from '@leon-hub/html-utils';
export function usePostponedContentLoad() {
    const isMainContentLoaded = ref(false);
    async function loadMainContent() {
        await nextTick();
        await nextAnimationFrame();
        isMainContentLoaded.value = true;
    }
    return {
        isMainContentLoaded,
        loadMainContent
    };
}
