import { getLocationPath, getLocationSearch } from '@leon-hub/service-locator-env';
import { getNewUri, prepareRedirectUri } from 'web/src/modules/core/services/router/utils';
export default function checkTrailingSlash(currentRedirectUri, currentUrlPrefix) {
    let newRedirectUri = currentRedirectUri;
    const pathname = getLocationPath();
    const search = getLocationSearch();
    const uri = `${pathname}${search}`;
    const newUri = getNewUri(currentUrlPrefix, currentUrlPrefix);
    if (newUri !== uri) {
        newRedirectUri = prepareRedirectUri(newUri);
        if (!process.env.VUE_APP_PRERENDER) window.history.replaceState(null, '', newUri);
    }
    return newRedirectUri;
}
