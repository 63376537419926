import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import registrationCompletionGuard from 'web/src/modules/core/services/router/navigation-guards/registration-completion/registrationCompletionGuard';
export default function createRegistrationNavigationGuard(router) {
    const { isLoggedIn } = useIsLoggedIn();
    const customerDataStore = useCustomerDataStore();
    router.beforeEach((to, from, next)=>{
        const guard = registrationCompletionGuard({
            isLoggedIn: isLoggedIn.value,
            isSimpleRegistrant: customerDataStore.isSimpleRegistrant,
            isExAllowedCountry: customerDataStore.isExAllowedCountry,
            isRegistrationFinish: customerDataStore.isRegistrationFinish,
            isCountryConfirmed: customerDataStore.isCountryConfirmed,
            isForbiddenCountry: customerDataStore.isForbiddenCountry,
            isAsoTermsConfirmationNeeded: customerDataStore.isAsoTermsConfirmationNeeded,
            to
        });
        if (guard) {
            router.next(next, guard);
            return;
        }
        next();
    });
}
