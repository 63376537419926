import { defineStore } from 'pinia';
import { ref } from 'vue';
const useScrollStore = defineStore('scroll', ()=>{
    const hasScrollableContent = ref(false);
    const scrollTop = ref(0);
    function setHasScrollableContent(value) {
        hasScrollableContent.value = value;
    }
    function setScrollTop(value) {
        scrollTop.value = value;
    }
    return {
        hasScrollableContent,
        setHasScrollableContent,
        scrollTop,
        setScrollTop
    };
});
export default useScrollStore;
