import RouteName from '@leon-hub/routing-config';
export default function createTransactionsRoute(router) {
    router.addProfileRoute({
        name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
        path: '/customer/history',
        // eslint-disable-next-line max-len
        component: ()=>import('web/src/modules/profile/submodules/customer-history/pages/CustomerHistoryListRouteComponent/CustomerHistoryListRouteComponent.vue'),
        title: '{{$t(\'WEB2_TRANSACTION_HISTORY\')}}',
        topBarProps: {
            hidePrefix: true
        },
        topBarType: void 0
    });
    router.addProfileRoute({
        name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
        path: '/customer/history/transaction/:group/:id/:accountType?',
        // eslint-disable-next-line max-len
        component: ()=>import('web/src/modules/profile/submodules/customer-history/pages/CustomerHistoryDetailsRouteComponent/CustomerHistoryDetailsRouteComponent.vue'),
        // eslint-disable-next-line max-len
        topBar: ()=>import('web/src/modules/profile/submodules/customer-history/layouts/CustomerHistoryTopBarRouteComponent/CustomerHistoryTopBarRouteComponent.vue')
    });
}
