/* eslint-disable sonarjs/no-identical-functions */ import { toRef, watch } from 'vue';
import { ErrorHandlerType } from '@leon-hub/api-sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { getInitSentryOptions } from './getInitSentryOptions';
import { trackEvents } from './trackEvents';
function mergeIgnoredErrors(ignoredErrors1, ignoredErrors2) {
    return [
        ...new Map([
            ...ignoredErrors1,
            ...ignoredErrors2
        ].map((e)=>[
                JSON.stringify([
                    e.error,
                    e.type
                ]),
                e
            ])).values()
    ];
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export default async function startSentry(app, router) {
    const { errorHandlerSettings, ignoredErrorsCommon, sentryOptions } = useSiteConfigStore();
    const login = toRef(useCustomerDataStore(), 'login');
    if (errorHandlerSettings && errorHandlerSettings.enabled && errorHandlerSettings.errorHandlerType === ErrorHandlerType.SENTRY) {
        mergeIgnoredErrors(ignoredErrorsCommon, errorHandlerSettings.ignoredErrors);
        let SentryInstance;
        // hack to set tags for android native logger, since the logger is instantiated asynchronously
        {
            const { initSentry } = await import('web/src/modules/sentry/utils');
            SentryInstance = initSentry(app, router, getInitSentryOptions());
        }
        if (SentryInstance) {
            trackEvents();
            const setUser = (newValue)=>SentryInstance.setUser(newValue ? {
                    id: newValue
                } : null);
            watch(login, (newValue)=>{
                setUser(newValue);
            }, {
                immediate: true
            });
        }
    }
}
