import RouteName from '@leon-hub/routing-config';
import createPromotionsRoutes from './promotions';
import createCmsRoutes from './cms';
import createTFARoutes from './tfa';
import createResponsibleGamblingRoutes from './responsible-gambling';
import createLoyaltyProgramRoutes from './loyalty-program';
import createTransactionsRoute from './transactions';
import createRestorePasswordRoutes from './restore-password';
import createVerifyEmailRoutes from './verify-email';
export default function createRoutes(router, param) {
    let { seoConfigs } = param;
    router.setSeoConfigs(seoConfigs);
    createPromotionsRoutes(router);
    createCmsRoutes(router);
    createTFARoutes(router);
    createResponsibleGamblingRoutes(router);
    createLoyaltyProgramRoutes(router);
    createTransactionsRoute(router);
    createRestorePasswordRoutes(router);
    createVerifyEmailRoutes(router);
    // Should be last one
    router.addRoute({
        name: Symbol('not-found-routes'),
        path: '/:pathMatch(.*)*',
        redirect: {
            name: RouteName.ERROR_NOT_FOUND_404,
            replace: true
        }
    });
}
