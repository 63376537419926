import { getLocationOrigin } from '@leon-hub/service-locator-env';
export default function createGetHref(router) {
    return (to)=>{
        const route = router.resolve(to);
        return {
            relative: route.href,
            absolute: `${getLocationOrigin()}${route.href}`
        };
    };
}
