import { AbstractError } from '@leon-hub/errors';
import { AppErrorCode } from './AppErrorCode';
export class ComponentWarning extends AbstractError {
    constructor(options){
        super({
            ...options,
            code: new AppErrorCode('COMPONENT_WARNING')
        });
        const vm = options.vm ?? null;
        if (vm) {
            this.addLogMetaData('props', vm.$props);
            this.addLogMetaData('name', vm.$options.name);
        }
        this.addLogMetaData('trace', options.trace);
    }
}
