import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import { ThemePlugin } from 'web/src/modules/theme/plugins';
import { I18nPlugin } from 'web/src/modules/i18n/plugins';
import { createStoreThemeGetter } from 'web/src/modules/theme/utils';
import SimpleApp from '../components/SimpleApp/SimpleApp.vue';
export default function createSimpleApp(component) {
    const app = createApp(SimpleApp);
    const pinia = createPinia();
    app.use(pinia);
    app.use(createHead());
    app.use(ThemePlugin, createStoreThemeGetter());
    app.use(I18nPlugin);
    app.component('SimpleAppDynamicComponent', component);
    app.mount('#app');
    // Simple apps must be screens with disabled scrolls
    document.body.classList.add('disable-scroll');
}
