import { defineStore } from 'pinia';
import { ref } from 'vue';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { loadGoogleFontsUrls } from 'web/src/utils/googleFonts/loadGoogleFontsUrls';
const primaryFonts = [
    'https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&&display=swap',
    'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap'
];
const secondaryFonts = [
    'https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;900&display=swap',
    'https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
];
const combinedFonts = [
    ...primaryFonts,
    ...secondaryFonts
];
const primaryFontFaces = [
    'Roboto'
];
const secondaryFontFaces = [
    'Mulish',
    'Prompt'
];
const combinedFontFaces = [
    ...primaryFontFaces,
    ...secondaryFontFaces
];
export const useFontsStore = defineStore('fonts', ()=>{
    const isFontsLoaded = ref(false);
    async function loadFonts() {
        if (process.env.VUE_APP_PRERENDER) isFontsLoaded.value = true;
        else try {
            await loadGoogleFontsUrls(combinedFonts, combinedFontFaces);
        } finally{
            await nextAnimationFrame();
            isFontsLoaded.value = true;
        }
    }
    return {
        isFontsLoaded,
        loadFonts
    };
});
