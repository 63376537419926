import { toRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import RouteName from '@leon-hub/routing-config-names';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { getLocationSearch } from '@leon-hub/service-locator-env';
import DeviceCustomerLoginStorage from 'web/src/modules/identity/utils/deviceCustomerLoginStorage';
import { useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useRegistrationIdleRedirectStore } from 'web/src/modules/registration/store';
const redirectCountKey = 'idleRedirectCount';
export default function useGuestRedirect() {
    const localStorage = useLocalStorageManager();
    const route = useRoute();
    const router = useRouter();
    const siteConfigStore = useSiteConfigStore();
    const idleRegistrationProps = toRef(siteConfigStore, 'idleRegistrationProps');
    const doRedirectGuestWithAccountToLogin = toRef(siteConfigStore, 'doRedirectGuestWithAccountToLogin');
    const redirectToLoginTimeout = toRef(siteConfigStore, 'redirectToLoginTimeout');
    const routerStore = useRouterStore();
    const currentRouteName = toRef(routerStore, 'currentRouteName');
    const startTimeRegistration = toRef(useRegistrationIdleRedirectStore(), 'startTimeRegistration');
    function redirect() {
        let lastLogin = DeviceCustomerLoginStorage.getLogin();
        if (doRedirectGuestWithAccountToLogin.value && redirectToLoginTimeout.value && lastLogin && route.redirectedFrom?.name !== RouteName.UNTRUSTED_DEVICE && route.redirectedFrom?.name !== RouteName.RESTORE_PASSWORD_ENTER_NEW_ONE && route.redirectedFrom?.name !== RouteName.REGISTRATION && route.redirectedFrom?.name !== RouteName.ANDROID) Timer.setTimeout(()=>{
            router.push({
                name: RouteName.LOGIN
            });
        }, 1000 * redirectToLoginTimeout.value);
        else {
            const { idleRedirectCount, marketingLinkParam, marketingLinkIdleRedirect } = idleRegistrationProps.value;
            let { idleTimeToRedirect } = idleRegistrationProps.value;
            const urlParams = new URLSearchParams(getLocationSearch());
            if (marketingLinkParam && urlParams.get(marketingLinkParam)) idleTimeToRedirect = marketingLinkIdleRedirect;
            const idleRedirectCountLocal = localStorage.getItem(redirectCountKey) || 0;
            if (Number(idleRedirectCountLocal) < idleRedirectCount && idleTimeToRedirect) Timer.setTimeout(()=>{
                lastLogin = DeviceCustomerLoginStorage.getLogin();
                const doNotRedirectToRegistrationPages = [
                    RouteName.LOGIN,
                    RouteName.ANDROID
                ];
                if (!lastLogin && !startTimeRegistration.value && !doNotRedirectToRegistrationPages.find((item)=>item === currentRouteName.value)) {
                    router.push({
                        name: RouteName.REGISTRATION
                    });
                    localStorage.setItem(redirectCountKey, `${Number(idleRedirectCountLocal) + 1}`);
                }
            }, 1000 * idleTimeToRedirect);
        }
    }
    return {
        redirect
    };
}
