import RouteName from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
function isPropsRouteName(name) {
    return name === RouteName.CMS || name === RouteName.CMS_PROMO_TERMS;
}
const getComponentPath = (termsRoute)=>termsRoute ? import('web/src/modules/promotions/pages/PromotionsTermsModalPage/PromotionsTermsModalPage.vue') : import('web/src/modules/cms/components/VCmsContent/VCmsContent.vue');
function getDefaultDesktopCmsRouteConfig(param) {
    let { name, path, title, cmsKey, isBigTitle } = param;
    return {
        name,
        path,
        title,
        isBigTitle,
        component: ()=>import('web/src/modules/cms/components/VCmsContent/VCmsContent.vue'),
        props: !!isPropsRouteName(name) || {
            cmsKey
        },
        leftSideBar: ()=>import('web/src/modules/sidebar/views/SidebarMenuKnowHowRouteComponent/SidebarMenuKnowHowRouteComponent.vue')
    };
}
function getDefaultModalCmsRouteConfig(param, modalPreset) {
    let { name, path, title, cmsKey, termsRoute } = param;
    return {
        name,
        path,
        modalPreset,
        component: ()=>getComponentPath(termsRoute),
        props: !!isPropsRouteName(name) || {
            cmsKey
        },
        title
    };
}
function addCmsRoute(router, config, modalPreset) {
    "1";
    if (modalPreset) router.addModalRoute(getDefaultModalCmsRouteConfig(config, modalPreset));
    else router.addDefaultRoute(getDefaultDesktopCmsRouteConfig(config));
}
export default function createCmsRoutes(router) {
    addCmsRoute(router, {
        name: RouteName.CMS,
        path: '/cms/:cmsKey',
        cmsKey: '',
        title: ''
    });
    addCmsRoute(router, {
        name: RouteName.CMS_PROMO_TERMS,
        path: '/promoterms/:cmsKey',
        termsRoute: true,
        cmsKey: '',
        title: '{{$t(\'WEB2_PROMO_TERMS_TITLE\')}}'
    }, ModalDesktopPreset.LargeDesktopModal);
    addCmsRoute(router, {
        name: RouteName.ABOUT_COMPANY_WEB2,
        path: '/about',
        cmsKey: 'ABOUT_COMPANY_WEB2',
        title: '{{$t(\'WEB2_ROUTING_TITLE_ABOUT_COMPANY\')}}',
        isBigTitle: true
    });
    addCmsRoute(router, {
        name: RouteName.AFFILIATE_PROGRAM_RULES_WEB2,
        path: '/affiliate-program-rules',
        cmsKey: 'WEB2_AFFILIATE_RULES',
        title: '{{$t(\'WEB2_AFFILIATE_PROGRAM_TERMS_TITLE\')}}'
    }, ModalDesktopPreset.LargeDesktopModal);
    addCmsRoute(router, {
        name: RouteName.BONUS_PROGRAM_CONDITIONS_WEB2,
        path: '/uslovija-bonusnoj-programmy',
        cmsKey: 'BONUS_PROGRAM_CONDITIONS_WEB2',
        title: '{{$t(\'WEB2_BONUS_CAMPAIGN_TERMS_TITLE\')}}'
    }, ModalDesktopPreset.SmallDesktopModal);
    addCmsRoute(router, {
        name: RouteName.SPORT_BETTING_RULES_WEB2,
        path: '/rules',
        cmsKey: 'SPORT_BETTING_GUIDELINES_DESKTOP_WEB2',
        title: '{{$t(\'WEB2_SPORT_BETTING_RULES_TITLE\')}}',
        isBigTitle: true
    });
    addCmsRoute(router, {
        name: RouteName.CMS_DATA_PROCESSING_AGREEMENT,
        path: '/data-processing-agreement',
        cmsKey: 'DATA_PROCESSING_AGREEMENT_WEB2',
        title: '{{$t(\'WEB2_DATA_PROCESSING_AGREEMENT\')}}'
    }, ModalDesktopPreset.LargeDesktopModal);
    addCmsRoute(router, {
        name: RouteName.CMS_BETTING_PARTICIPATION_AGREEMENT,
        path: '/betting-participation-agreement',
        cmsKey: 'REG_GUIDELINES_DESKTOP_WEB2',
        title: '{{$t(\'WEB2_REG_RULES_TITLE\')}}'
    }, ModalDesktopPreset.LargeDesktopModal);
    addCmsRoute(router, {
        name: RouteName.CMS_HOW_TO_BET,
        path: '/make-a-bet',
        cmsKey: 'HOW_TO_BET_WEB2',
        title: '{{$t(\'WEB2_ROUTING_TITLE_HOW_TO_BET\')}}'
    });
    addCmsRoute(router, {
        name: RouteName.CONTACTS,
        path: '/contacts',
        cmsKey: 'WEB2_CONTACTS',
        title: '{{$t(\'JS_WEB2_NAVIGATION_CONTACTS\')}}',
        isBigTitle: true
    });
    addCmsRoute(router, {
        name: RouteName.REFERRAL_PROGRAM_AGREEMENT,
        path: '/referral-program-agreement',
        cmsKey: 'WEB2_REFERRAL_PROGRAM_AGREEMENT_DESCRIPTION',
        title: '{{$t(\'WEB2_REFERRAL_PROGRAM_AGREEMENT_TITLE\')}}'
    }, ModalDesktopPreset.LargeDesktopModal);
    addCmsRoute(router, {
        name: RouteName.RESPONSIBLE_GAMBLING_WEB2,
        path: '/responsible-gambling',
        cmsKey: 'RESPONSIBLE_GAMBLING_WEB2',
        title: '{{$t(\'WEB2_RESPONSIBLE_GAMING\')}}'
    });
    addCmsRoute(router, {
        name: CasinoRouteName.WEB2_CASINO_RULES,
        path: '/casino-rules',
        cmsKey: 'WEB2_CASINO_RULES',
        title: '{{$t(\'WEB2_CASINO_RULES\')}}',
        isBigTitle: true
    });
    addCmsRoute(router, {
        name: RouteName.TELEGRAM_BOT,
        path: '/telegram-bot',
        cmsKey: 'WEB2_TELEGRAM_INFO',
        title: '{{$t(\'WEB2_TELEGRAM_BOT_TTL\')}}'
    });
}
