import { _ as _define_property } from "@swc/helpers/_/_define_property";
let BusEvent = class BusEvent {
    toString() {
        return this.key;
    }
    constructor(key){
        _define_property(this, "key", void 0);
        this.key = key;
    }
};
_define_property(BusEvent, "LAYOUT_CONTENT_SCROLL", new BusEvent('LAYOUT_CONTENT_SCROLL'));
_define_property(BusEvent, "LAYOUT_CONTENT_SET_SCROLL", new BusEvent('LAYOUT_CONTENT_SET_SCROLL'));
_define_property(BusEvent, "LAYOUT_CONTENT_SCROLL_TOP", new BusEvent('LAYOUT_CONTENT_SCROLL_TOP'));
_define_property(BusEvent, "MODAL_CONTENT_SCROLL_TOP", new BusEvent('MODAL_CONTENT_SCROLL_TOP'));
_define_property(BusEvent, "MODAL_CONTENT_SCROLL", new BusEvent('MODAL_CONTENT_SCROLL'));
_define_property(BusEvent, "LAYOUT_CONTENT_SCROLL_DISABLE", new BusEvent('LAYOUT_CONTENT_SCROLL_DISABLE'));
_define_property(BusEvent, "LAYOUT_CONTENT_SCROLL_ENABLE", new BusEvent('LAYOUT_CONTENT_SCROLL_ENABLE'));
_define_property(BusEvent, "OPEN_CHAT", new BusEvent('OPEN_CHAT'));
_define_property(BusEvent, "SIDE_MENU_TOGGLE", new BusEvent('SIDE_MENU_TOGGLE'));
_define_property(BusEvent, "SIDE_MENU_SOFT_CLOSE", new BusEvent('SIDE_MENU_SOFT_CLOSE'));
_define_property(BusEvent, "SPORT_EVENTS_STATUS_CHANGED", new BusEvent('SPORT_EVENTS_STATUS_CHANGED'));
_define_property(BusEvent, "SPORT_EVENTS_RUNNERS_CHANGED", new BusEvent('SPORT_EVENTS_RUNNERS_CHANGED'));
_define_property(BusEvent, "NEW_BET_HAS_BEEN_PLACED", new BusEvent('NEW_BET_HAS_BEEN_PLACED'));
_define_property(BusEvent, "SHOW_SLIP_MODAL_WARNING", new BusEvent('SHOW_SLIP_MODAL_WARNING'));
_define_property(BusEvent, "CLAIM_TO_SLIP_CLOSE", new BusEvent('CLAIM_TO_SLIP_CLOSE'));
_define_property(BusEvent, "SLIP_SELECTION_CHANGE", new BusEvent('SLIP_SELECTION_CHANGE'));
_define_property(BusEvent, "SLIP_ENTRY_DELETE_CLICK", new BusEvent('SLIP_ENTRY_DELETE_CLICK'));
_define_property(BusEvent, "API_ERROR", new BusEvent('API_ERROR'));
_define_property(BusEvent, "CAPTCHA_VERIFICATION", new BusEvent(// sdk type CaptchaType
'CAPTCHA_VERIFICATION'));
_define_property(BusEvent, "CAPTCHA_SERVICE_ERROR", new BusEvent('CAPTCHA_SERVICE_ERROR'));
_define_property(BusEvent, "CAPTCHA_CHALLENGE_IS_CLOSED", new BusEvent('CAPTCHA_CHALLENGE_IS_CLOSED'));
_define_property(BusEvent, "CATALOG_CLICK", new BusEvent('CATALOG_CLICK'));
_define_property(BusEvent, "SCROLL_TO_ELEMENT_ID", new BusEvent('SCROLL_TO_ELEMENT_ID'));
_define_property(BusEvent, "MODAL_SCROLL_TO_ELEMENT", new BusEvent('MODAL_SCROLL_TO_ELEMENT'));
_define_property(BusEvent, "QA_SCROLLBAR_SCROLL_TO", new BusEvent('QA_SCROLLBAR_SCROLL_TO'));
_define_property(BusEvent, "REDIRECT", new BusEvent('REDIRECT'));
_define_property(BusEvent, "DEPOSIT_BONUS_WIDGET_OPEN_LIST", new BusEvent('DEPOSIT_BONUS_WIDGET_OPEN_LIST'));
export { BusEvent as default };
