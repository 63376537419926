import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
import { ApiConnectionError } from '@leon-hub/api';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ComponentLoadError',
    emits: [
        "load-error",
        "loaded"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        onMounted(()=>{
            emit('load-error', new ApiConnectionError({
                message: 'Failed to load async component'
            }));
            emit('loaded');
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VEmpty), null, null, 512)), [
                [
                    _directive_auto_id,
                    'ComponentLoadError'
                ]
            ]);
        };
    }
});
