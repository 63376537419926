import { getQueryParams, getBuiltinConfig } from '@leon-hub/service-locator-env';
import { localStorageManager } from '@leon-hub/local-storage';
import { featureKey } from '../constants';
function isForbiddenByDevIp(isRemoteDevIp) {
    // We can't check inline(config is not inlined) dev-ip restrictions for cordova.
    if (isRemoteDevIp) return false;
    return !getBuiltinConfig().isNT;
}
export const isAutomationPluginEnabled = (isRemoteDevIp)=>{
    if (isForbiddenByDevIp(isRemoteDevIp)) return false;
    if (featureKey in getQueryParams()) return true;
    return !!localStorageManager.getItem(featureKey);
};
