import { QueryParameterName } from '@leon-hub/query-manager/src/enums/index';
import { getBuiltinConfig, getLocationPath } from '@leon-hub/service-locator-env';
import { useQueryManager } from 'web/src/modules/core/composables';
export default function getAppAdHeader() {
    const appAdHeader = useQueryManager().getParameter(QueryParameterName.APP_AD);
    if (appAdHeader) return appAdHeader;
    const initConfig = getBuiltinConfig();
    const adPaths = initConfig?.adPaths;
    if (adPaths) {
        const pathname = getLocationPath();
        for (const [key, value] of Object.entries(adPaths))if (pathname.includes(key)) return value;
    }
    return null;
}
