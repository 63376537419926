import { nextTick, onMounted } from 'vue';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useGuestHandler from './useGuestHandler';
import useGuestRedirect from './useGuestRedirect';
export default function useGuestAppController(props, emit) {
    const api = useGraphqlClient();
    const { onLogout } = useGuestHandler();
    const { redirect: guestRedirect } = useGuestRedirect();
    onMounted(async ()=>{
        try {
            await nextTick();
            await nextAnimationFrame();
            await Promise.all([
                onLogout(props.isAppInit)
            ].concat(props.isAppInit ? [
                api.flush()
            ] : []));
        } catch (error) {
            emit('load-error', error);
        }
        emit('loaded');
        if (props.isAppInit) guestRedirect();
    });
}
