import { ref } from 'vue';
import { BusEvent, useBusSafeSubscribe } from '@leon-hub/event-bus';
export default function useDesktopModalScroll() {
    const scrollbar = ref();
    useBusSafeSubscribe(BusEvent.MODAL_SCROLL_TO_ELEMENT, (param)=>{
        let { element, onlyIfNeeded } = param;
        if (scrollbar.value) scrollbar.value.scrollToElement(element, {
            onlyIfNeeded
        });
        else element?.scrollIntoView();
    });
    return {
        scrollbar
    };
}
