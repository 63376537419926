import { AbstractErrorCode } from '@leon-hub/errors';
const defaultDebugErrorCode = Reflect.construct(class DebugErrorCode extends AbstractErrorCode {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(code){
        super(code);
    }
}, [
    'DebugError'
]);
export default defaultDebugErrorCode;
