import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { ModalWidth } from '../../enums';
export default function getModalWidth(preset) {
    switch(preset){
        case ModalDesktopPreset.SearchDesktopModal:
            return ModalWidth.MEDIUM;
        case ModalDesktopPreset.EuroBattlePassModal:
            return ModalWidth.MEDIUM_PLUS;
        case ModalDesktopPreset.LargeDesktopModal:
        case ModalDesktopPreset.ProviderSlotDesktopModal:
            return ModalWidth.LARGE;
        case ModalDesktopPreset.ProfileDesktopModal:
            return ModalWidth.BIG;
        default:
            return ModalWidth.DEFAULT;
    }
}
