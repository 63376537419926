import { normalizeError } from '@leon-hub/errors';
import { isOptionalString } from '@leon-hub/guards';
import RouteName from '@leon-hub/routing-config';
import { Deferred } from '@leon-hub/utils';
import { useDesktopModalStore } from 'web/src/modules/core/store';
import ModalOpenedRouteError from '../errors/ModalOpenedRouteError';
import prefetchRouteComponents from './prefetchRouteComponents';
import processAfterRoute from './history-state/processAfterRoute';
let modalToOpen;
function createBeforeEach(router) {
    router.beforeEach(async (to, from, next)=>{
        try {
            const { modalPreset } = to.meta;
            isOptionalString(modalPreset);
            const desktopModalStore = useDesktopModalStore();
            const { setDesktopModal } = desktopModalStore;
            if (desktopModalStore.desktopModal && !modalPreset) {
                setDesktopModal(void 0);
                if (!router.isPopStateDetected()) {
                    await router.closeModal();
                    next(new ModalOpenedRouteError());
                    if (from.fullPath !== to.fullPath) router.push(to);
                    return;
                }
            }
            if (modalPreset) {
                if (!from.name) {
                    // direct link to modal
                    router.next(next, {
                        name: RouteName.HOME,
                        replace: true
                    });
                    modalToOpen = to;
                    return;
                }
                const deferred = new Deferred();
                // eslint-disable-next-line no-inner-declarations
                function modalsNext(route) {
                    deferred.resolve(route);
                }
                await prefetchRouteComponents(to, from, modalsNext, router);
                const resolvedPromise = await deferred.promise;
                if (resolvedPromise && resolvedPromise.name !== to.name) {
                    router.next(next, resolvedPromise);
                    return;
                }
                processAfterRoute(to, from, router);
                setDesktopModal({
                    ...to.meta.desktopModal,
                    preset: modalPreset,
                    route: to
                });
                next(new ModalOpenedRouteError());
                return;
            }
            next();
        } catch (err) {
            next(normalizeError(err));
        }
    });
}
function createAfterEach(router) {
    router.afterEach(()=>{
        if (modalToOpen) {
            router.push({
                name: String(modalToOpen.name),
                params: modalToOpen.params,
                query: modalToOpen.query
            });
            modalToOpen = void 0;
        }
    });
}
export default function createDesktopModalNavigationGuard(router) {
    createBeforeEach(router);
    createAfterEach(router);
}
