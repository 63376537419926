import { voidify } from '@leon-hub/utils';
import { handle } from './utils';
export const appModulePrefetchPlugin = (_, options)=>{
    const { router, prefix } = options;
    const listener = (event)=>handle({
            event,
            router,
            prefix
        });
    document.addEventListener('pointerdown', voidify(listener), {
        passive: true,
        capture: true
    });
};
