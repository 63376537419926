import { AbstractError } from '@leon-hub/errors';
import { AppModuleErrorCode } from './AppModuleErrorCode';
export class AppModuleError extends AbstractError {
    constructor(options){
        super({
            ...options,
            code: options?.code ?? AppModuleErrorCode.UNEXPECTED_APP_MODULE_ERROR
        });
    }
}
