import { QueryParameterName } from '@leon-hub/query-manager';
import RouteName from '@leon-hub/routing-config-names';
import { isObject, isString } from '@leon-hub/guards';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { Json, Timer } from '@leon-hub/utils';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { useDesktopModalStore, useRouterStore } from 'web/src/modules/core/store';
import { importComponent, createGetHref } from 'web/src/modules/core/utils';
import { isSameRoute } from './utils';
import processAfterRoute from './navigation-guards/history-state/processAfterRoute';
let customSeoConfigs = {};
let isRouteReplace = false;
let isModalReplace = false;
let doSaveScrollPosition = false;
function replace(router) {
    return (location)=>{
        isRouteReplace = true;
        return router.replace(location);
    };
}
function canNavigateTo(router) {
    return (location)=>{
        const current = router.currentRoute;
        const target = router.resolve(location);
        return !isSameRoute(target, current.value);
    };
}
function isDuplicatedRouteWithModal(router, location) {
    if (useDesktopModalStore().desktopModal) {
        const resolvedLocation = router.resolve(location);
        if (resolvedLocation.fullPath === router.currentRoute.value.fullPath) return true;
    }
    return false;
}
function resolveRedirectLocation(router) {
    return (location, code)=>{
        if (process.env.VUE_APP_PRERENDER) {
            let query = {};
            if ('string' == typeof location) {
                const url = new URL(location);
                for (const [key, value] of url.searchParams.entries())query[key] = value;
            } else query = location.query ?? {};
            for (const key of [
                QueryParameterName.PRERENDER,
                QueryParameterName.PRERENDER_HOST,
                QueryParameterName.PRERENDER_IP
            ])if (query[key]) delete query[key];
            const redirectLocation = router.resolve({
                ...router.resolve(location),
                query
            });
            return {
                name: RouteName.REDIRECT_SEO,
                state: {
                    redirectLocation: redirectLocation.href,
                    redirectCode: code
                }
            };
        }
        return location;
    };
}
function resolve301Location(router) {
    return (location)=>resolveRedirectLocation(router)(location, '301');
}
function resolve302Location(router) {
    return (location)=>resolveRedirectLocation(router)(location, '302');
}
function isOpenedByPush(router) {
    return ()=>!!router.currentRoute.value.query[QueryParameterName.FROM_PUSH];
}
function removePushQuery(router) {
    return ()=>{
        const { query, name, params } = router.currentRoute.value;
        if (query?.[QueryParameterName.FROM_PUSH]) delete query[QueryParameterName.FROM_PUSH];
        replace(router)({
            name: name || '',
            params,
            query
        });
    };
}
function getPath(name, path) {
    isString(name);
    return customSeoConfigs[name]?.path || path;
}
function getImportComponent(component) {
    return ()=>importComponent(component);
}
function getRouteBaseConfig(config) {
    return {
        name: config.name,
        path: getPath(config.name, config.path),
        meta: {
            ...config.meta ?? {},
            access: config.access,
            prefetch: config.prefetch,
            seoController: config.seoController
        }
    };
}
function getBlankRouteConfig(config) {
    const baseConfig = getRouteBaseConfig(config);
    return {
        ...baseConfig,
        component: getImportComponent(config.component),
        props: config.props
    };
}
function getPrefetchComponent(config) {
    return getBlankRouteConfig({
        ...config,
        component: ()=>import('web/src/modules/errors/pages/RedirectSeoRouteComponent/RedirectSeoRouteComponent.vue')
    });
}
function addDefaultDesktopRoute(router, config) {
    const baseConfig = getRouteBaseConfig(config);
    router.addRoute({
        ...baseConfig,
        meta: {
            ...baseConfig.meta,
            ...config.leftSideBar ? {
                hasLeftSidebar: true
            } : {},
            ...config.fixedBar ? {
                hasFixedBar: true
            } : {},
            ...config.isFooterHidden ? {
                isFooterHidden: true
            } : {},
            ...config.isRightSidebarHidden ? {
                isRightSidebarHidden: true
            } : {},
            ...config.isLeftSidebarToggleHidden ? {
                isLeftSidebarToggleHidden: true
            } : {},
            ...config.theme ? {
                theme: config.theme
            } : {}
        },
        components: {
            default: getImportComponent(config.component),
            ...config.fixedBar ? {
                fixedBar: getImportComponent(config.fixedBar)
            } : {},
            ...config.navigation ? {
                navigation: getImportComponent(config.navigation)
            } : {},
            ...config.leftSideBar ? {
                leftSideBar: getImportComponent(config.leftSideBar)
            } : {},
            ...config.title ? {
                title: getImportComponent(()=>import('web/src/modules/core/components/DesktopTitle/DesktopTitle.vue'))
            } : {},
            ...config.contentLoader ? {
                contentLoader: getImportComponent(config.contentLoader)
            } : {},
            ...config.topAuthorizedSlot ? {
                topAuthorizedSlot: getImportComponent(config.topAuthorizedSlot)
            } : {}
        },
        props: {
            default: config.props ?? {},
            ...config.title ? {
                title: {
                    title: config.title,
                    isBig: config.isBigTitle
                }
            } : {}
        }
    });
}
function addDefaultRoute(router) {
    return (config)=>{
        "1";
        addDefaultDesktopRoute(router, config);
    };
}
function getBaseDesktopModalConfig(config) {
    if (!config.modalPreset) throw new Error(`Modal preset not provided. Config=${Json.stringify(config)}`);
    const baseConfig = getRouteBaseConfig(config);
    return {
        ...baseConfig,
        meta: {
            ...baseConfig.meta,
            ...config.theme ? {
                theme: config.theme
            } : {},
            hasTopBar: true,
            modalPreset: config.modalPreset,
            desktopModal: {
                isNoMinHeightLimit: config.isNoMinHeightLimit,
                default: {
                    component: config.component,
                    props: config.props
                },
                navigation: config.navigation ? {
                    component: config.navigation
                } : void 0,
                topBar: {
                    component: config.topBar ?? (()=>import('web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue')),
                    props: {
                        ...config.title ? {
                            title: config.title
                        } : {},
                        ...config.topBarType ? {
                            type: config.topBarType
                        } : {},
                        ...config.topBarProps ?? {}
                    }
                }
            }
        },
        component: VEmpty
    };
}
function getProfileDesktopModalRouteConfig(config) {
    const baseConfig = getBaseDesktopModalConfig({
        ...config,
        modalPreset: ModalDesktopPreset.ProfileDesktopModal
    });
    baseConfig.meta;
    isObject(baseConfig.meta.desktopModal);
    baseConfig.meta.desktopModal.isProfile = true;
    return baseConfig;
}
// eslint-disable-next-line max-len
function addProfileRoute(router) {
    let isAnonymous = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return (config)=>{
        "1";
        router.addRoute(getProfileDesktopModalRouteConfig(isAnonymous ? config : {
            ...config,
            access: CustomerRouteAccessRole.AUTHORIZED
        }));
    };
}
function addModalRoute(router) {
    return (config)=>{
        "1";
        router.addRoute(getBaseDesktopModalConfig(config));
    };
}
function resolveByPopState(resolve) {
    let timer = 0;
    const popstateListener = ()=>{
        window.removeEventListener('popstate', popstateListener);
        if (timer) {
            Timer.clearTimeout(timer);
            timer = 0;
        }
        resolve();
    };
    window.addEventListener('popstate', popstateListener);
    timer = Timer.setTimeout(popstateListener, 5000);
}
async function preventCloseModalEvents() {
    let isPrevented = false;
    const closeEvent = {
        preventDefault: ()=>{
            isPrevented = true;
        }
    };
    const desktopStore = useDesktopModalStore();
    if (desktopStore.closeModalEvents.length) await Promise.all(desktopStore.closeModalEvents.map((callback)=>callback(closeEvent)));
    return isPrevented;
}
function closeModalInner(router, offset) {
    const desktopStore = useDesktopModalStore();
    return new Promise((resolve)=>{
        const store = useRouterStore();
        const { getModalRouteHistoryDelta } = store;
        const delta = getModalRouteHistoryDelta() + offset;
        if (0 === delta && desktopStore.desktopModal) {
            desktopStore.setDesktopModal(void 0);
            const current = router.currentRoute.value.fullPath;
            window.history.replaceState({
                ...window.history.state,
                current,
                meta: void 0,
                prevMeta: void 0
            }, '', router.resolve(current).href);
            processAfterRoute(router.currentRoute.value, router.currentRoute.value, router);
            resolve();
            return;
        }
        if (delta >= 0) {
            resolve();
            return;
        }
        resolveByPopState(resolve);
        router.go(delta);
    });
}
function closeModal(router) {
    return async function() {
        let offset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        if (await preventCloseModalEvents()) return;
        await closeModalInner(router, offset);
    };
}
export default function getAppVueRouter(router) {
    // @ts-ignore
    return {
        ...router,
        setSeoConfigs (configs) {
            customSeoConfigs = configs;
        },
        getPrevHistoryUrl () {
            return useRouterStore().previousRouteUrl;
        },
        isPopStateDetected () {
            return useRouterStore().isPopStateDetected;
        },
        getHref: createGetHref(router),
        canNavigateTo: canNavigateTo(router),
        resolveRedirectLocation: resolveRedirectLocation(router),
        resolve301location: resolve301Location(router),
        resolve302location: resolve302Location(router),
        pushRedirect (location, code, options) {
            const redirectLocation = 301 === code ? this.resolve301location(location) : this.resolve302location(location);
            return this.push(redirectLocation, options);
        },
        push301 (location, options) {
            return this.pushRedirect(location, 301, options);
        },
        push302 (location, options) {
            return this.pushRedirect(location, 302, options);
        },
        replace (location) {
            if (isDuplicatedRouteWithModal(router, location)) return this.closeModal();
            return replace(router)(location);
        },
        getParam (param) {
            const { desktopModal } = useDesktopModalStore();
            return desktopModal ? desktopModal.route.params[param] : router.currentRoute.value.params[param];
        },
        getQuery (param) {
            const { desktopModal } = useDesktopModalStore();
            return desktopModal ? desktopModal.route.query[param] : router.currentRoute.value.query[param];
        },
        getName () {
            const { desktopModal } = useDesktopModalStore();
            return desktopModal ? desktopModal.route.name : router.currentRoute.value.name;
        },
        isReplaced () {
            return isRouteReplace;
        },
        processAfterEach () {
            isRouteReplace = false;
            isModalReplace = false;
            doSaveScrollPosition = false;
            useRouterStore().resetPopState();
        },
        isModalReplaced () {
            return isModalReplace;
        },
        doSaveScrollPosition () {
            return doSaveScrollPosition;
        },
        push (location, options) {
            if (options?.cancelOnPopstate && this.isPopStateDetected()) return Promise.resolve(void 0);
            if (options?.saveScrollPosition) doSaveScrollPosition = true;
            if (isDuplicatedRouteWithModal(router, location)) return this.closeModal();
            return router.push(location);
        },
        isOpenedByPush: isOpenedByPush(router),
        removePushQuery: removePushQuery(router),
        back (defaultLocation) {
            if (window.history.state?.back) router.back();
            else replace(router)(defaultLocation || {
                name: RouteName.HOME
            });
        },
        backAsync (defaultLocation) {
            if (!window.history.state?.back) {
                replace(router)(defaultLocation || {
                    name: RouteName.HOME
                });
                return Promise.resolve();
            }
            return new Promise((resolve)=>{
                resolveByPopState(resolve);
                router.back();
            });
        },
        forceBack (defaultLocation) {
            if (!window.history.state) {
                this.go(-2);
                return;
            }
            const historyLengthDiff = window.history.length - (window.history.state.historyLength || 0);
            if (window.history.state?.back && historyLengthDiff > 0) this.go(-(1 + historyLengthDiff));
            else this.back(defaultLocation);
        },
        async replaceModal (route) {
            isModalReplace = true;
            await closeModal(this)(1);
            replace(router)(route);
        },
        closeModal () {
            return closeModal(this)(0);
        },
        addBlankRoute (route) {
            const config = getBlankRouteConfig(route);
            router.addRoute({
                ...config,
                meta: {
                    ...config.meta ?? {},
                    isBlank: true
                }
            });
            return this;
        },
        addPrefetchRoute (route) {
            router.addRoute(getPrefetchComponent(route));
            return this;
        },
        addLandingRoute (route) {
            const config = getBlankRouteConfig(route);
            router.addRoute({
                ...config,
                meta: {
                    ...config.meta ?? {},
                    isLanding: true
                }
            });
            return this;
        },
        addDefaultRoute (route) {
            addDefaultRoute(router)(route);
            return this;
        },
        addModalRoute (route) {
            addModalRoute(router)(route);
            return this;
        },
        addProfileRoute (route) {
            addProfileRoute(router)(route);
            return this;
        },
        addAnonymousProfileRoute (route) {
            addProfileRoute(router, true)(route);
            return this;
        },
        next (originalNext, nextTo) {
            if (nextTo) {
                if (isObject(nextTo) && nextTo.replace) isRouteReplace = true;
                originalNext(nextTo);
                if (isObject(nextTo) && nextTo.state) window.history.replaceState({
                    ...window.history.state,
                    ...nextTo.state
                }, '');
            } else originalNext();
        }
    };
}
