import { createAutomationDirective } from '../directive';
import { DIRECTIVE_NAME } from '../constants';
export const AutomationPlugin = {
    install (app, param) {
        let { elemAttrName, isEnabled = false } = param;
        app.directive(DIRECTIVE_NAME, isEnabled ? createAutomationDirective({
            elemAttrName
        }) : {});
    }
};
