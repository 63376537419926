import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { getCustomerTimezoneOffset } from '@leon-hub/utils';
let ServerDate = class ServerDate {
    static setServerTime(timestamp) {
        ServerDate.timeShift = timestamp - Date.now();
    }
    static now() {
        return Date.now() + ServerDate.timeShift;
    }
    /**
   * Get customer's timezone offset for system time rounded to 0.5
   * (For any customer's timezone we must get same offset for same time)
   * For example:
   * Actual time is 12.00 at GMT+3
   * User can select any timezone in system settings but
   * If he has time set to 19.00 we got +9 because right now 19.00 in Tokyo (GMT+9)
   * Same for 01.00 we got -9 because right now in Anchorage 01.00 (GMT-9)
   * And we got +10.30 for time 20.30 same to Adelaide (GTM+10.30)
   */ static getCustomerTimezoneOffset() {
        return getCustomerTimezoneOffset(ServerDate.timeShift, new Date().getTimezoneOffset());
    }
    static getTimeShift() {
        return ServerDate.timeShift;
    }
};
/**
   * Shift between local and server time
   * @type {number}
   * @private
   */ _define_property(ServerDate, "timeShift", 0);
export { ServerDate as default };
