import { QueryParameterName } from '@leon-hub/query-manager';
import { getLocationOrigin } from '@leon-hub/service-locator-env';
export default function prepareRedirectUri(uri) {
    const url = new URL(`${getLocationOrigin()}${uri}`);
    for (const key of [
        QueryParameterName.PRERENDER,
        QueryParameterName.PRERENDER_HOST,
        QueryParameterName.PRERENDER_IP
    ])url.searchParams.delete(key);
    return `${url.pathname}${url.search}`;
}
