import { useRoute } from 'vue-router';
import { computed } from 'vue';
import RouteName from '@leon-hub/routing-config-names';
export default function useLayoutDesktopInfo() {
    const currentRoute = useRoute();
    const hasLeftSideBar = computed(()=>!!currentRoute.meta.hasLeftSidebar);
    const hasRightSideBar = computed(()=>(hasLeftSideBar.value || currentRoute.name === RouteName.SPORT_EVENT_DETAILS) && !currentRoute.meta.isRightSidebarHidden);
    const isFullWidth = computed(()=>!hasLeftSideBar.value && !hasRightSideBar.value);
    return {
        hasLeftSideBar,
        hasRightSideBar,
        isFullWidth
    };
}
