import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { AbstractError } from '@leon-hub/errors/src/internal';
import { requestIdleCallback, Timer, getViewportDimension } from '@leon-hub/utils';
import { useLayoutInfo } from 'web/src/modules/core/composables';
import LayoutLanding from 'web/src/modules/core/components/LayoutLanding/LayoutLanding.vue';
import LayoutBlank from 'web/src/modules/core/components/LayoutBlank/LayoutBlank.vue';
import LayoutDesktopDefault from 'web/src/modules/core/components/LayoutDesktopDefault/LayoutDesktopDefault.vue';
import { getErrorAppComponentByError } from 'web/src/modules/errors/utils';
import { useErrorHandler } from 'web/src/modules/core/composables/errors';
import { useRootStore } from 'web/src/modules/core/store';
import { useThemeStore } from 'web/src/modules/theme/store';
import GoogleService from 'web/src/modules/analytics/services/googleService';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useMainApp() {
    const { isLanding, isBlank } = useLayoutInfo();
    const router = useRouter();
    const rootStore = useRootStore();
    const { recalculateTheme } = useThemeStore();
    const { setAppMainContentLoaded } = rootStore;
    const customerDataStore = useCustomerDataStore();
    const isAppMainContentLoaded = toRef(rootStore, 'isAppMainContentLoaded');
    const login = toRef(()=>customerDataStore.login);
    // eslint-disable-next-line sonarjs/no-collapsible-if
    const mainComponent = computed(()=>{
        if (isLanding.value) return LayoutLanding;
        if (isBlank.value) return LayoutBlank;
        return LayoutDesktopDefault;
    });
    const isAppInit = ref(true);
    const initAppError = ref(null);
    const isPostponedControllerVisible = ref(false);
    const errorComponent = computed(()=>initAppError.value ? getErrorAppComponentByError(initAppError.value) : void 0);
    async function showContent() {
        setAppMainContentLoaded();
    }
    function onAppControllerError(error) {
        const isSilent = error instanceof AbstractError && error.silent;
        const { handleError } = useErrorHandler(router);
        if (isAppInit.value && !isSilent) {
            // eslint-disable-next-line no-console
            console.log(`App Controller Error: ${error}`);
            initAppError.value = error;
        } else handleError(error);
        showContent();
    }
    function onAppControllerLoaded() {
        showContent();
        isAppInit.value = false;
        GoogleService.getInstance().pushEvent('pageVisit', {
            login: login.value
        });
    }
    function registerGlobalListeners() {
        let dimensions = getViewportDimension();
        document.documentElement.style.setProperty('--viewport-height', `${dimensions.viewportHeight}px`);
        document.documentElement.scrollTo({
            top: 0
        });
        window.addEventListener('resize', ()=>{
            const newDimensions = getViewportDimension();
            document.documentElement.style.setProperty('--viewport-height', `${newDimensions.viewportHeight}px`);
            dimensions = newDimensions;
        });
    }
    function initAutofocusRoutes() {
        if (process.env.VUE_APP_OS_IOS) Timer.setTimeout(()=>{
            requestIdleCallback(()=>{
                Promise.all([
                    // eslint-disable-next-line max-len
                    import('web/src/modules/support/submodules/feedback-legacy/pages/SupportFeedbackLegacyRoutePage/SupportFeedbackLegacyRoutePage.vue'),
                    import('web/src/modules/search/pages/SearchRoutePage/SearchRoutePage.vue')
                ]).then();
            });
        }, 500);
    }
    function init() {
        onMounted(()=>{
            Timer.setTimeout(()=>{
                isPostponedControllerVisible.value = true;
            }, 3000);
            initAutofocusRoutes();
            registerGlobalListeners();
        });
    }
    init();
    return {
        errorComponent,
        mainComponent,
        isPostponedControllerVisible,
        isAppInit,
        isAppMainContentLoaded,
        onAppControllerError,
        onAppControllerLoaded
    };
}
