import { computed } from 'vue';
import { useTheme } from 'web/src/modules/theme/composables';
import { useHtmlClassList } from 'web/src/modules/core/composables';
import useAppClassList from '../../../utils/useAppClassList';
export default function useSimpleApp() {
    const { theme } = useTheme();
    const classList = computed(()=>[
            ...useAppClassList(theme.value.toString()),
            'app__content-ready'
        ]);
    useHtmlClassList(classList);
}
