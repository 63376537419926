import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { AppEmitter } from '@leon-hub/app-emitter';
export class AppModuleApiProvider {
    static reset() {
        AppModuleApiProvider.api = {
            bus: new AppEmitter()
        };
    }
    static getApi() {
        const { api } = AppModuleApiProvider;
        AppModuleApiProvider.name;
        return api;
    }
}
_define_property(AppModuleApiProvider, "api", void 0);
