import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useGuestAppController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'GuestAppController',
    props: {
        isAppInit: {
            type: Boolean
        }
    },
    emits: [
        "load-error",
        "loaded"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        useGuestAppController(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, null, 512)), [
                [
                    _directive_auto_id,
                    'GuestAppController'
                ]
            ]);
        };
    }
});
