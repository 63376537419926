import { UnhandledRejection } from '@leon-hub/app-errors/src/UnhandledRejection';
import { ComponentWarning } from '@leon-hub/app-errors/src/ComponentWarning';
import { ComponentError } from '@leon-hub/app-errors';
import { logger } from '@leon-hub/logging';
import { isOpenSearchLoggableError } from '@leon-hub/errors-whitelist';
import { isObject } from '@leon-hub/guards';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
function isApiError(argument) {
    return isObject(argument) && 'code' in argument && 'FirebaseError' !== argument.name;
}
function handleApiError(param) {
    let { error, bus, event } = param;
    bus.emit(BusEvent.API_ERROR, {
        error,
        event
    });
}
function setupGlobalUnhandledRejectionError(bus) {
    window.addEventListener('unhandledrejection', (event)=>{
        try {
            if (event.defaultPrevented) return;
            event.preventDefault();
            if (isApiError(event.reason)) {
                handleApiError({
                    error: event.reason,
                    bus,
                    event
                });
                return;
            }
            if (isOpenSearchLoggableError(event.reason)) logger.error(new UnhandledRejection({
                event
            }));
        } catch (error) {
            logger.error('Unhandled Promise Rejection processing error', {
                event,
                error
            });
        }
    });
}
export default function configureErrorHandler(app) {
    const bus = useEventsBus();
    setupGlobalUnhandledRejectionError(bus);
    // eslint-disable-next-line no-param-reassign
    app.config.errorHandler = (error, vm, info)=>{
        if (isApiError(error)) {
            handleApiError({
                error,
                bus
            });
            return;
        }
        if (isOpenSearchLoggableError(error)) {
            const componentError = new ComponentError({
                prefix: '[Vue ERROR]: ',
                originalError: error,
                info,
                vm
            });
            logger.error(componentError);
        }
    };
    // eslint-disable-next-line no-param-reassign
    app.config.warnHandler = (message, vm, trace)=>{
        const componentWarning = new ComponentWarning({
            message,
            trace,
            vm
        });
        logger.warn(componentWarning);
    };
    return app;
}
