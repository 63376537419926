import { AppModuleError } from '../entities';
import { useAsyncWrapper } from './useAsyncWrapper';
function loadModule(getApi, asyncModule, condition, options) {
    if (condition) {
        const api = getApi();
        if (options?.preload) asyncModule().catch((err)=>{
            api.bus.emit('log:error', new AppModuleError({
                message: 'Unable to preload async app module',
                cause: err
            }));
        });
        return ()=>useAsyncWrapper(asyncModule, getApi, options?.args);
    }
    return ()=>useAsyncWrapper(void 0, getApi, options?.args);
}
export const createAppModuleLoaderFactory = (getApi)=>loadModule.bind(null, getApi);
