import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { HomePageType } from '@leon-hub/api-sdk';
import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import useSiteConfigStore from './useSiteConfigStore';
import { fetchRoutingConfigs } from './utils';
const useRouterStore = defineStore('router', ()=>{
    // State
    const seoConfigs = ref({});
    const isBackButtonInTopBar = ref(false);
    const currentRoute = ref(null);
    const previousRoute = ref(null);
    const currentRouteIndex = ref(0);
    const lastNoModalRouteIndex = ref(0);
    const realCurrentRouteName = ref('');
    const previousRouteName = ref(void 0);
    const nextRouteName = ref(void 0);
    const previousRouteUrl = ref(void 0);
    const lastAnonymousRouteIndex = ref(0);
    const lastAuthorizedRouteIndex = ref(0);
    const isPopStateDetected = ref(false);
    // Getters
    const siteConfigStore = useSiteConfigStore();
    const homePageType = toRef(siteConfigStore, 'homePageType');
    const isSportHomePageType = computed(()=>homePageType.value === HomePageType.SPORTS);
    const isEGSHomePageType = computed(()=>homePageType.value === HomePageType.SLOTS || homePageType.value === HomePageType.LIVE_GAMES);
    const currentRouteName = computed(()=>currentRoute.value?.name);
    const isCurrentRouteHasTopBar = computed(()=>!!currentRoute.value?.meta.hasTopBar);
    // Actions
    async function updateAppRouteConfigs() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        handleAppRouteConfigs(await fetchRoutingConfigs({
            silent
        }));
    }
    function handleAppRouteConfigs(result) {
        const { routing } = result;
        const config = JSON.parse(routing);
        seoConfigs.value = config.reduce((accumulator, item)=>{
            accumulator[item.name] = {
                path: item.path,
                metaInfo: item.metaInfo,
                seoKey: item.seoKey,
                seoData: item.seoData
            };
            return accumulator;
        }, {});
    }
    function setBackButtonInTopBar(value) {
        isBackButtonInTopBar.value = value;
    }
    function updateIndexes(to) {
        if (!to.meta.access || to.meta.access === CustomerRouteAccessRole.ANONYMOUS) setLastAnonymousRouteIndex(window.history.state?.position || 0);
        if (!to.meta.access || to.meta.access === CustomerRouteAccessRole.AUTHORIZED) setLastAuthorizedRouteIndex(window.history.state?.position || 0);
    }
    function setCurrentRoute(to) {
        previousRoute.value = currentRoute.value;
        currentRoute.value = to;
        updateIndexes(to);
    }
    function setCurrentRouteIndex(value) {
        currentRouteIndex.value = value;
    }
    function setLastNoModalRouteIndex(value) {
        lastNoModalRouteIndex.value = value;
    }
    function setLastAnonymousRouteIndex(value) {
        lastAnonymousRouteIndex.value = value;
    }
    function setLastAuthorizedRouteIndex(value) {
        lastAuthorizedRouteIndex.value = value;
    }
    function setRealCurrentRouteName(value) {
        realCurrentRouteName.value = value;
    }
    function setPreviousRouteName(value) {
        previousRouteName.value = value;
    }
    function setPreviousRouteUrl(value) {
        previousRouteUrl.value = value;
    }
    function getModalRouteHistoryDelta() {
        if (window.history.length <= 1) return 0;
        const delta = currentRouteIndex.value - lastNoModalRouteIndex.value;
        return delta > 0 ? -delta : 0;
    }
    function getAuthorizedRouteHistoryDelta() {
        if (0 === lastAuthorizedRouteIndex.value) return 0;
        const delta = currentRouteIndex.value - lastAuthorizedRouteIndex.value;
        return delta > 0 ? -delta : 0;
    }
    function getAnonymousRouteHistoryDelta() {
        if (0 === lastAnonymousRouteIndex.value) return 0;
        const delta = currentRouteIndex.value - lastAnonymousRouteIndex.value;
        return delta > 0 ? -delta : 0;
    }
    function getLoginRedirectRoute() {
        return window.history.state?.toRoute || window.history.state?.back;
    }
    function resetPopState() {
        isPopStateDetected.value = false;
    }
    function setNextRouteName(value) {
        nextRouteName.value = value;
    }
    function registerPopState() {
        window.addEventListener('popstate', ()=>{
            isPopStateDetected.value = true;
        });
    }
    registerPopState();
    return {
        currentRoute,
        previousRoute,
        seoConfigs,
        isBackButtonInTopBar,
        isEGSHomePageType,
        isSportHomePageType,
        currentRouteName,
        realCurrentRouteName,
        previousRouteName,
        previousRouteUrl,
        isPopStateDetected,
        isCurrentRouteHasTopBar,
        nextRouteName,
        updateAppRouteConfigs,
        handleAppRouteConfigs,
        setBackButtonInTopBar,
        setCurrentRouteIndex,
        setCurrentRoute,
        setLastNoModalRouteIndex,
        getModalRouteHistoryDelta,
        setRealCurrentRouteName,
        getLoginRedirectRoute,
        setPreviousRouteName,
        setPreviousRouteUrl,
        getAuthorizedRouteHistoryDelta,
        getAnonymousRouteHistoryDelta,
        resetPopState,
        setNextRouteName
    };
});
export default useRouterStore;
