import { useBusSafeSubscribe } from '@leon-hub/event-bus';
const EventsBusPlugin = {
    install (app) {
        // eslint-disable-next-line no-param-reassign
        app.config.globalProperties.$busSafeSubscribe = function() {
            for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
                rest[_key] = arguments[_key];
            }
            const [event, callback, rawOptions] = rest;
            const controls = useBusSafeSubscribe(event, callback, getOptions(rawOptions));
            controls.subscribe();
            return controls;
        };
    }
};
function getOptions(rawOptions) {
    if ('object' == typeof rawOptions) return {
        onBeforeMountDisabled: true,
        ...rawOptions
    };
    return {
        onDeactivatedDisabled: rawOptions
    };
}
export default EventsBusPlugin;
