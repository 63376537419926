import { once } from 'lodash';
import { EditionValuePlatformCordova, EditionValueRenderingCsr } from '@leon-hub/environment-editions';
import { normalizeError } from '@leon-hub/errors';
import { isObject } from '@leon-hub/guards';
import { useIcons } from '../composables';
import { VirtualSportIconName, SocialIconName, CurrenciesIconName, CasinoIconName, LegacyIconName, SystemIconName, SportIconName, EsportIconName } from '../enums';
function isDataUrl(value) {
    return value.startsWith('data:');
}
function isAbsoluteUrl(value) {
    return /^(https?:)?\/\/\w/.test(value);
}
function normalizeImagePath(appUrl, imageRawSource) {
    if ("csr" !== EditionValueRenderingCsr && !process.env.VUE_APP_STORYBOOK || "web" === EditionValuePlatformCordova) return imageRawSource;
    return isAbsoluteUrl(imageRawSource) || isDataUrl(imageRawSource) ? imageRawSource : `${'' === appUrl ? '' : new URL(appUrl).origin}/${imageRawSource.replace(/^\/+/, '')}`;
}
const importCallback = (icons, esModule, iconName)=>{
    if (isObject(esModule) && isObject(esModule.default)) {
        const { url } = esModule.default;
        if ('string' == typeof url) {
            icons.set(iconName, url);
            return;
        }
    }
    icons.set(iconName, `#icon-${iconName}`);
};
function createOnError(icon) {
    return function onIconLoadError(err) {
        const message = `Unable to load '${icon}' icon due to error: ${err.message}`;
        if ('complete' === document.readyState) // eslint-disable-next-line no-console
        console.warn(message);
        else // eslint-disable-next-line no-console
        document.addEventListener('DOMContentLoaded', ()=>console.warn(message));
    };
}
export const loadIcons = once(async ()=>{
    const { icons } = useIcons();
    async function handleIcon(iconName, loader) {
        try {
            const value = await loader(iconName);
            importCallback(icons, value, iconName);
        } catch (err) {
            createOnError(iconName)(normalizeError(err));
        }
    }
    async function registerIcons(loader, iconMap) {
        await Promise.all(Object.values(iconMap).map((id)=>handleIcon(id, loader)));
    }
    function applyVirtualSportsIcons(promises) {
        promises.push(// eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/virtual-sport/${iconName}.svg`), VirtualSportIconName));
    }
    function applyCasinoIcons(promises) {
        promises.push(// eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/casino/${iconName}.svg`), CasinoIconName));
    }
    // eslint-disable-next-line import/no-dynamic-require
    // eslint-disable-next-line import/no-dynamic-require
    // eslint-disable-next-line import/no-dynamic-require
    // eslint-disable-next-line import/no-dynamic-require
    // eslint-disable-next-line import/no-dynamic-require
    // eslint-disable-next-line no-console
    function loadAsHiddenImg(src) {
        const img = document.createElement('img');
        img.src = src;
        img.style.position = 'absolute';
        img.style.visibility = 'hidden';
        img.style.left = '-10000px';
        function clean() {
            if (img.parentNode) img.remove();
        }
        img.addEventListener('load', clean);
        img.addEventListener('remove', clean);
        document.body.appendChild(img);
    }
    const promises = [
        // eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/system/${iconName}.svg`), LegacyIconName),
        // eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/system/${iconName}.svg`), SystemIconName),
        // eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/sport/${iconName}.svg`), SportIconName),
        // eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/esport/${iconName}.svg`), EsportIconName),
        // eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/currency/${iconName}.svg`), CurrenciesIconName),
        // eslint-disable-next-line import/no-dynamic-require
        registerIcons((iconName)=>require(`../assets/icons/social/${iconName}.svg`), SocialIconName)
    ];
    "1";
    applyCasinoIcons(promises);
    "1";
    applyVirtualSportsIcons(promises);
    await Promise.allSettled(promises);
});
