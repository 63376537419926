import { clearCookies, getCookie, setCoookie } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export default function useCookies() {
    const api = useGraphqlClient();
    async function set(options) {
        await setCoookie(api, (node)=>node.mutations.cookies.setCookie, {
            options: {
                cookie: {
                    ...options,
                    httpOnly: true
                }
            }
        }, {
            silent: true
        });
    }
    async function get(name) {
        const cookies = await getCookie(api, (node)=>node.queries.cookies.getCookies, {
            options: {}
        }, {
            silent: true
        });
        const cookie = cookies.cookies.find((item)=>item.name === name);
        return cookie ? cookie.value : void 0;
    }
    async function getAll() {
        const cookies = await getCookie(api, (node)=>node.queries.cookies.getCookies, {
            options: {}
        }, {
            silent: true
        });
        return cookies.cookies;
    }
    async function clear() {
        await clearCookies(api, (node)=>node.mutations.cookies.clearCookies, {
            options: {}
        }, {
            silent: true
        });
    }
    return {
        get,
        getAll,
        set,
        clear
    };
}
