import { isObject, isUndefined } from '@leon-hub/guards';
import { useRouterStore } from 'web/src/modules/core/store';
import processAfterRoute from './history-state/processAfterRoute';
import prefetchRouteComponents from './prefetchRouteComponents';
function getContentElement() {
    "1";
    return document.getElementById('js__content-scroll');
}
export default function createBaseNavigationGuard(router) {
    router.beforeEach(async (to, from, originalNext)=>{
        useRouterStore().setNextRouteName(to.name);
        const next = (nextTo)=>{
            isUndefined(nextTo) || isObject(nextTo);
            return router.next(originalNext, nextTo);
        };
        if (!router.isPopStateDetected()) window.history.replaceState({
            ...window.history.state,
            scrollTop: from.meta.resetScrollPosition ? 0 : getContentElement()?.scrollTop || 0
        }, '');
        await prefetchRouteComponents(to, from, next, router);
    });
    router.afterEach((to, from)=>{
        const { isPopStateDetected } = router;
        if (to.fullPath !== from.fullPath || !useRouterStore().currentRoute?.meta?.modalPreset || isPopStateDetected()) processAfterRoute(to, from, router);
        useRouterStore().setRealCurrentRouteName(to.name);
        useRouterStore().setNextRouteName(void 0);
        if (to.fullPath === from.fullPath && window.history.state?.current !== to.fullPath && !isPopStateDetected) router.closeModal();
        if (to.name !== from.name && to.meta.transitionTo !== from.meta.transitionTo) {
            if (to.meta.transitionTo) // eslint-disable-next-line no-param-reassign
            to.meta.transition = to.meta.transitionTo;
            if (from.meta.transitionFrom) // eslint-disable-next-line no-param-reassign
            to.meta.transition = from.meta.transitionFrom;
        }
    });
}
