function createAppRouterInterface(router) {
    return Object.freeze({
        push (route, parameters) {
            return new Promise((resolve, reject)=>{
                router.push({
                    name: route,
                    params: parameters
                }).then(resolve).catch(reject);
            });
        },
        replace (route, parameters) {
            return new Promise((resolve, reject)=>{
                router.replace({
                    name: route,
                    params: parameters
                }).then(resolve).catch(reject);
            });
        },
        back () {
            router.back();
        },
        forward () {
            router.forward();
        }
    });
}
export default function initAppRouterInterface(router) {
    window.mainApp.router = createAppRouterInterface(router);
}
