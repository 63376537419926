/* eslint-disable @typescript-eslint/no-unused-vars,class-methods-use-this */ import { _ as _ts_decorate } from "@swc/helpers/_/_ts_decorate";
import { _ as _ts_metadata } from "@swc/helpers/_/_ts_metadata";
import { Memoized, ImplementsStatic } from '@leon-hub/utils';
let BaseDebugLogger = class BaseDebugLogger {
    log() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
    }
    extend(namespace) {
        return this;
    }
    static create() {
        return new BaseDebugLogger();
    }
};
export { BaseDebugLogger as default };
_ts_decorate([
    Memoized({
        shared: true
    }),
    _ts_metadata("design:type", Function),
    _ts_metadata("design:paramtypes", []),
    _ts_metadata("design:returntype", "undefined" == typeof DebugLogger ? Object : DebugLogger)
], BaseDebugLogger, "create", null);
BaseDebugLogger = _ts_decorate([
    ImplementsStatic()
], BaseDebugLogger);
