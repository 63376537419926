export function mergeIgnoredErrors(ignoredErrors1) {
    let ignoredErrors2 = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    return [
        ...new Map([
            ...ignoredErrors1,
            ...ignoredErrors2
        ].map((e)=>[
                JSON.stringify([
                    e.error,
                    e.type
                ]),
                e
            ])).values()
    ];
}
