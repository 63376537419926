import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'LayoutBlank',
    setup (__props) {
        // empty
        return (_ctx, _cache)=>{
            const _component_router_view = _resolveComponent("router-view");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_component_router_view, null, null, 512)), [
                [
                    _directive_auto_id,
                    'LayoutBlank'
                ]
            ]);
        };
    }
});
