import { computed, toRef } from 'vue';
import { useDowntimeAlertStore } from 'web/src/modules/downtime/store';
import { useFrontNotificationsStore } from 'web/src/modules/front-notifications/store';
export default function useAuthorizedSnackbars() {
    const downtimeAlertModule = useDowntimeAlertStore();
    const frontNotificationModule = useFrontNotificationsStore();
    const isDowntimeAlertVisible = toRef(downtimeAlertModule, 'isAlertVisible');
    const isVideoVerificationAlert = toRef(frontNotificationModule, 'isVideoVerificationAlert');
    const isIdentificationStatusAlertVisible = computed(()=>!isDowntimeAlertVisible.value);
    return {
        isDowntimeAlertVisible,
        isIdentificationStatusAlertVisible,
        isVideoVerificationAlert
    };
}
