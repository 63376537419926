import { _ as _define_property } from "@swc/helpers/_/_define_property";
let RegCompletionRouteParametersHandler = class RegCompletionRouteParametersHandler {
    static setRedirectLocation(redirectLocation) {
        RegCompletionRouteParametersHandler.redirectLocation = redirectLocation;
    }
    static getRedirectLocation() {
        return RegCompletionRouteParametersHandler.redirectLocation;
    }
};
_define_property(RegCompletionRouteParametersHandler, "redirectLocation", void 0);
export { RegCompletionRouteParametersHandler as default };
