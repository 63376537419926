import { defineStore } from 'pinia';
import { ref } from 'vue';
import { NavigationGroup } from '@leon-hub/navigation-config';
import { getPromotionNavigationConfig } from '@leon-hub/api-sdk/src/sdk/sdk';
import { isNavigationConfig } from '@leon-hub/navigation-config/src/types/NavigationConfig';
import { fetchDefaultNavigationConfig } from 'web/src/modules/core/store/utils/fetchDefaultNavigationConfig';
import useSyncState from 'web/src/modules/core/store/composables/useSyncState';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const useNavigationStore = defineStore('navigation', ()=>{
    const api = useGraphqlClient();
    const navigationConfig = ref({});
    function handleDefaultNavigationConfig(config) {
        navigationConfig.value = config;
    }
    async function updateDefaultNavigationConfig() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        handleDefaultNavigationConfig(await fetchDefaultNavigationConfig(silent));
    }
    async function fetchPromotionsNavigationConfig() {
        let silent = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        const result = await getPromotionNavigationConfig(api, (node)=>node.queries.system.getNavigation, {}, {
            silent
        });
        const parsedNavigationConfig = JSON.parse(result.navigation);
        isNavigationConfig(parsedNavigationConfig);
        const key = NavigationGroup.PROMOTION_CATEGORIES_NAVIGATION;
        navigationConfig.value[key] = parsedNavigationConfig[key];
    }
    useSyncState(updateDefaultNavigationConfig, 'navigation');
    return {
        updateDefaultNavigationConfig,
        handleDefaultNavigationConfig,
        fetchPromotionsNavigationConfig,
        navigationConfig
    };
});
export default useNavigationStore;
