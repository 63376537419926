import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
import { useSimpleApp } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SimpleApp',
    setup (__props) {
        useSimpleApp();
        return (_ctx, _cache)=>{
            const _component_SimpleAppDynamicComponent = _resolveComponent("SimpleAppDynamicComponent");
            const _component_metainfo = _resolveComponent("metainfo");
            return _openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_component_SimpleAppDynamicComponent),
                _createCommentVNode("", true),
                _createVNode(_component_metainfo)
            ], 64);
        };
    }
});
