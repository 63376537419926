export function combineGoogleFontUrls(urls) {
    let domain;
    const params = [];
    for (const url of urls){
        const [urlDomain, urlParams] = url.split('?');
        if (void 0 === domain) domain = urlDomain;
        params.push(urlParams);
    }
    return `${domain}?${params.join('&')}`;
}
