/**
 * Allow to await redraw next frame and after change dom state do something else
 * Useful for slow devices
 * @return {Promise<void>}
 */ export function nextAnimationFrame() {
    // https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
    return new Promise((resolve)=>{
        requestAnimationFrame(()=>{
            requestAnimationFrame(()=>{
                resolve();
            });
        });
    });
}
