import noop from 'lodash/noop';
import { doSaveLog, RequestOptionsPriority } from '@leon-hub/api-sdk';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const makeSaveLogRequest = (input)=>{
    const apiClient = useGraphqlClient();
    doSaveLog(apiClient, (node)=>node.mutations.logging.saveLog, {
        options: input
    }, {
        silent: true,
        priority: RequestOptionsPriority.LOW
    }).// do not log errors for saveLog operation
    catch(noop);
};
export default makeSaveLogRequest;
