import { useAuthStore } from 'web/src/modules/auth/store';
import { useUserStore } from 'web/src/modules/user/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
export default function initCustomerAppInterface() {
    const customerDataStore = useCustomerDataStore();
    const userStore = useUserStore();
    const authStore = useAuthStore();
    window.mainApp = window.mainApp || {};
    window.mainApp.user = {
        getLogin () {
            return customerDataStore.login;
        },
        isLoggedIn () {
            return authStore.isLoggedIn;
        },
        async logout () {
            return userStore.logout();
        },
        async forgotSession () {
            useAuthStore().forgotSession();
            return Promise.resolve();
        }
    };
}
