/* eslint-disable no-param-reassign */ import { AutomationPlugin, disableAutomationPlugin, enableAutomationPlugin, isAutomationPluginEnabled } from '@leon-hub/directive-automation/plugin';
import { useRootStore } from 'web/src/modules/core/store';
import configureErrorHandler from './configureErrorHandler';
export default function configureVueApp(app) {
    const automationPluginEnabledStatus = isAutomationPluginEnabled(useRootStore().isDevIP);
    app.use(AutomationPlugin, {
        elemAttrName: 'data-auto-id',
        isEnabled: automationPluginEnabledStatus
    });
    if (automationPluginEnabledStatus) {
        disableAutomationPlugin();
        enableAutomationPlugin();
    } else disableAutomationPlugin();
    app = configureErrorHandler(app);
    return app;
}
