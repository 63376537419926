import getAppVueRouter from 'web/src/modules/core/services/router/getAppVueRouter';
import prefetchRouteComponents from 'web/src/modules/core/services/router/navigation-guards/prefetchRouteComponents';
export function normalizeHref(href, pathPrefix) {
    // Right now lang prefix is not part of vue-router system, so we need to manually clean it.
    // TODO: Refactor language as router base path.
    return href.startsWith(`/${pathPrefix}`) ? href.replace(new RegExp(`^/${pathPrefix}`), '') : href;
}
export function getRouteLocationNormalized(router, href) {
    const { matched, name, params, query, fullPath, hash, redirectedFrom, path, meta } = router.resolve(href);
    if (!name) return null;
    return {
        matched,
        name,
        params,
        query,
        fullPath,
        hash,
        redirectedFrom,
        path,
        meta
    };
}
export async function runPrefetch(router, route) {
    try {
        await new Promise((resolve, reject)=>{
            prefetchRouteComponents(route, router.currentRoute.value, ()=>resolve(), getAppVueRouter(router)).catch(reject);
        });
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Unable to prefetch', route.name, err);
    }
}
export async function handle(param) {
    let { router, event, prefix } = param;
    event.stopPropagation();
    const { target } = event;
    if (target instanceof HTMLAnchorElement) {
        const href = target.getAttribute('href');
        if (href && !href.startsWith('http')) {
            const hrefWithoutDuplicatePrefix = normalizeHref(href, prefix.value);
            const route = getRouteLocationNormalized(router, hrefWithoutDuplicatePrefix);
            if (!route) {
                // eslint-disable-next-line no-console
                console.warn('Unexpected prefetch state');
                return;
            }
            await runPrefetch(router, route);
        }
    }
}
