import { Timer } from '@leon-hub/utils';
import { whenClientNetworkIdle } from '@leon-hub/idle';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { combineGoogleFontUrls } from './combineGoogleFontUrls';
export async function loadGoogleFontsUrls(urls) {
    let fontFaces = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    return new Promise((resolve)=>{
        let timeout = 0;
        const element = document.createElement('div');
        element.style.visibility = 'hidden';
        element.style.height = '0px';
        element.innerHTML = '&nbsp;';
        const resolveLoadedFonts = async ()=>{
            if (timeout) Timer.clearTimeout(timeout);
            await nextAnimationFrame();
            await document.fonts.ready;
            element.remove();
            resolve();
        };
        const triggerFontsLoading = ()=>{
            if (fontFaces.length > 0) {
                for (const fontFace of fontFaces){
                    const spanElement = document.createElement('span');
                    spanElement.style.fontFamily = fontFace;
                    element.appendChild(spanElement);
                }
                document.body.appendChild(element);
            }
            resolveLoadedFonts();
        };
        // In case fonts not received in 10seconds
        timeout = Timer.setTimeout(resolveLoadedFonts, 10000);
        const style = document.createElement('style');
        // Hide text until fonts are loaded to prevent flashing.
        style.innerHTML = '* { color: rgba(0,0,0,0) !important; }';
        document.head.appendChild(style);
        whenClientNetworkIdle({
            // Cordova fonts loading during splashcreen
            timeout: 3000,
            interval: 200
        }).then(()=>{
            const combinedUrl = combineGoogleFontUrls(urls);
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.crossOrigin = 'anonymous';
            link.href = combinedUrl;
            link.onload = ()=>{
                triggerFontsLoading();
                if (style.parentNode) document.head.removeChild(style);
            };
            link.onerror = ()=>{
                resolveLoadedFonts();
                if (style.parentNode) document.head.removeChild(style);
            };
            document.head.appendChild(link);
        });
    });
}
