// Tuple-based event signature
import { _ as _define_property } from "@swc/helpers/_/_define_property";
export class AppEmitter {
    on(eventName, callback) {
        if (!this.listeners.has(eventName)) this.listeners.set(eventName, []);
        const currentListeners = this.listeners.get(eventName);
        currentListeners?.push(callback);
    }
    once(eventName, callback) {
        var _this = this;
        const onceWrapper = function() {
            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                args[_key] = arguments[_key];
            }
            callback(...args);
            _this.off(eventName, onceWrapper);
        };
        this.on(eventName, onceWrapper);
    }
    off(eventName, callback) {
        if (void 0 === eventName) this.listeners.clear();
        else if (callback) {
            const currentListeners = this.listeners.get(eventName);
            if (currentListeners) this.listeners.set(eventName, currentListeners.filter((listener)=>listener !== callback));
        } else this.listeners.delete(eventName);
    }
    emit() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
        this.emitFrom([], rest[0], ...rest.slice(1));
    }
    emitFrom(sourceEmitter, eventName) {
        for(var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++){
            args[_key - 2] = arguments[_key];
        }
        if (sourceEmitter.includes(this)) return;
        const currentListeners = this.listeners.get(eventName);
        if (currentListeners) for (const listener of currentListeners)listener(...args);
        for (const connection of this.connections)connection.emitFrom([
            ...sourceEmitter,
            this
        ], eventName, ...args);
    }
    detectCycle(node, state) {
        const index = state.indexOf(node);
        // If node is found and it's not a direct connection
        if (-1 !== index && state.length - index > 2) return true;
        // Cycle detected
        if (-1 === index) {
            state.push(node);
            for (const neighbor of node.connections)if (this.detectCycle(neighbor, state)) return true;
            // Backtrack
            state.pop();
        }
        return false;
    }
    connect(emitter) {
        if (this === emitter) throw new Error('Unable to connect an emitter to itself');
        if (this !== emitter) {
            this.connections.push(emitter);
            emitter.connections.push(this);
        }
        if (this.detectCycle(this, [])) throw new Error('Cycle has been detected');
    }
    setConnections(connections) {
        this.connections = connections;
    }
    disconnect(emitter) {
        this.setConnections(this.connections.filter((item)=>emitter !== item));
        emitter.setConnections(emitter.connections.filter((item)=>this !== item));
    }
    constructor(){
        _define_property(this, "connections", []);
        _define_property(this, "listeners", new Map());
    }
}
