import { Deferred } from '@leon-hub/utils';
import { importComponent } from 'web/src/modules/core/utils';
import AbstractPrefetch from '../prefetch/AbstractPrefetch';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache = new WeakMap();
export default async function prefetchRouteComponents(to, from, next, router) {
    const { prefetch } = to.meta;
    if (prefetch) {
        const PrefetchClass = await importComponent(()=>Promise.resolve(prefetch()));
        const cachedPrefetchService = cache.get(prefetch);
        const prefetchService = cachedPrefetchService ?? Reflect.construct(PrefetchClass, []);
        if (!(prefetchService instanceof AbstractPrefetch)) throw new Error('prefetchService is not instance of AbstractPrefetch');
        if (!cachedPrefetchService) cache.set(prefetch, prefetchService);
        const deferred = new Deferred();
        // eslint-disable-next-line no-inner-declarations
        function nextGuard(value) {
            deferred.resolve(value);
        }
        await prefetchService.start(router, to, from, nextGuard);
        const resolvedPromise = await deferred.promise;
        if (resolvedPromise) next(resolvedPromise);
        else next();
    }
    next();
}
