import { defineStore } from 'pinia';
import { ref } from 'vue';
export const useModulesSyncStore = defineStore('modules-sync', ()=>{
    const syncStateCounter = ref(0);
    function syncState() {
        syncStateCounter.value += 1;
    }
    return {
        syncStateCounter,
        syncState
    };
});
