import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { defineAsyncComponent } from 'vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { useDepositNotification } from 'web/src/modules/front-notifications/components/DepositNotification/composables';
import useSupport from 'web/src/modules/support/composables/useSupport';
import { useAuthorizedAppController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AuthorizedAppController',
    props: {
        isAppInit: {
            type: Boolean
        }
    },
    emits: [
        "load-error",
        "loaded"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const EgsLoyaltyProgramLevelDoneModal = // eslint-disable-next-line max-len
        defineAsyncComponent(()=>import('web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramLevelDoneModal/CasinoLoyaltyProgramLevelDoneModal.vue'));
        const CustomerNotificationsModalController = defineAsyncComponent(// eslint-disable-next-line max-len
        ()=>import('web/src/modules/customer-notifications/views/CustomerNotificationsModalController/CustomerNotificationsModalController.vue'));
        // eslint-disable-next-line max-len
        defineAsyncComponent(()=>import('web/src/modules/support/submodules/widget/views/TheSupportWidget/TheSupportWidget.vue'));
        const { isEgsLoyaltyDoneModalVisible } = useAuthorizedAppController(props, emit);
        const { isLiveChatAvailable, isHelpMode } = useSupport();
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _unref(isEgsLoyaltyDoneModalVisible) ? (_openBlock(), _createBlock(_unref(EgsLoyaltyProgramLevelDoneModal), {
                    key: 0
                })) : _createCommentVNode("", true),
                _createVNode(_unref(CustomerNotificationsModalController)),
                _createCommentVNode("", true),
                _createCommentVNode("", true)
            ], 64));
    }
});
