import { useSiteConfigStore } from 'web/src/modules/core/store';
import { mergeIgnoredErrors } from './mergeIgnoredErrors';
export const getInitSentryOptions = ()=>{
    const { errorHandlerSettings, ignoredErrorsCommon, sentryOptions } = useSiteConfigStore();
    const version = "6.100.0";
    const ignoredErrors = mergeIgnoredErrors(ignoredErrorsCommon, errorHandlerSettings?.ignoredErrors);
    return {
        release: version,
        product: "li",
        skin: "default",
        platform: "web",
        layout: "desktop",
        modernity: "modern",
        parameters: {
            ...sentryOptions,
            tracesSampleRate: sentryOptions.browserTracingEnabled && sentryOptions.tracesSampleRate ? Number(sentryOptions.tracesSampleRate) : void 0
        },
        ignoredErrors,
        consoleLogLevels: errorHandlerSettings?.consoleLogLevels ?? [],
        // https://docs.sentry.io/platforms/javascript/configuration/filtering/
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i
        ],
        browserTracingEnabled: !!errorHandlerSettings?.browserTracingEnabled,
        sessionReplayEnabled: !!errorHandlerSettings?.sessionReplayEnabled,
        profilingEnabled: !!errorHandlerSettings?.profilingEnabled
    };
};
