import { EditionKey } from '@leon-hub/environment-editions';
import { Web2FontFamily } from '@leon-hub/api-sdk';
import { useConditionValue } from 'web/src/modules/core/utils/useConditionValue';
export default function useAppClassList(theme, isFullScreen, fontFamily) {
    const classList = [];
    for (const item of [
        EditionKey.PRODUCT,
        EditionKey.RENDERING,
        EditionKey.PLATFORM,
        EditionKey.MODERNITY,
        EditionKey.ENV,
        EditionKey.SKIN,
        EditionKey.STYLE,
        EditionKey.OS,
        EditionKey.LAYOUT,
        EditionKey.BROWSER
    ]){
        const environmentValue = useConditionValue(item);
        if (environmentValue) classList.push(`app__${item}--${environmentValue}`);
    }
    if (theme) classList.push(`app__theme--${theme.toLowerCase()}`);
    if (isFullScreen) classList.push('app__fullscreen');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    if (fontFamily && fontFamily !== Web2FontFamily.DEFAULT) classList.push(`app__font--${fontFamily.toLowerCase()}`);
    return classList;
}
