import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { defineAsyncComponent } from 'vue';
import VEmpty from 'web/src/components/Empty/components/VEmpty';
import { usePostponedAppController } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PostponedAppController',
    setup (__props) {
        const PushModalComponent = // eslint-disable-next-line max-len
        defineAsyncComponent(()=>import('web/src/modules/push-notifications/components/WebPushModal/WebPushModalRouteComponent.vue'));
        const { isModalVisible } = usePostponedAppController();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(isModalVisible) ? _withDirectives((_openBlock(), _createBlock(_unref(PushModalComponent), {
                key: 0
            }, null, 512)), [
                [
                    _directive_auto_id,
                    'PostponedAppController'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
