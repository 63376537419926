import RouteName from '@leon-hub/routing-config';
export default function createPromotionsRoutes(router) {
    const pathPromotionsPage = '/promo/:categoryId?';
    "1";
    router.addDefaultRoute({
        name: RouteName.PROMOTIONS,
        path: pathPromotionsPage,
        isRightSidebarHidden: false,
        component: ()=>import('web/src/modules/promotions/pages/PromotionsRoutePage/PromotionsRoutePage.vue'),
        // eslint-disable-next-line max-len
        leftSideBar: ()=>import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue')
    });
    router.addDefaultRoute({
        name: RouteName.PROMOTION_DETAILS,
        path: '/promo/:categoryId/:actionUrl',
        // eslint-disable-next-line max-len
        component: ()=>import('web/src/modules/promotions/pages/PromotionDetailsRoutePage/PromotionDetailsRoutePage.vue'),
        // eslint-disable-next-line max-len
        leftSideBar: ()=>import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue')
    });
    router.addDefaultRoute({
        name: RouteName.BONUS_WALLET,
        path: '/bonus-wallet',
        component: ()=>import('web/src/modules/promotions/pages/BonusWalletRoutePage/BonusWalletRoutePage.vue'),
        // eslint-disable-next-line max-len
        leftSideBar: ()=>import('web/src/modules/promotions/layouts/PromotionsSidebarRouteComponent/PromotionsSidebarRouteComponent.vue')
    });
} // eslint-disable-next-line max-len
 // eslint-disable-next-line max-len
 // eslint-disable-next-line max-len
 // eslint-disable-next-line max-len
 // eslint-disable-next-line max-len
