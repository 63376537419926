import { computed, nextTick, onMounted, toRef } from 'vue';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useAuthorizedHandler from './useAuthorizedHandler';
import { getEgsLoyaltyStore } from './utils';
export default function useAuthorizedAppController(props, emit) {
    let isEgsLoyaltyDoneModalVisible;
    let afterMount = ()=>{};
    const api = useGraphqlClient();
    const { onLogin } = useAuthorizedHandler();
    const egsLoyaltyStore = getEgsLoyaltyStore();
    "1";
    isEgsLoyaltyDoneModalVisible = computed(()=>{
        const isShowLevelDoneModal = toRef(egsLoyaltyStore, 'isShowLevelDoneModal');
        return isShowLevelDoneModal.value;
    });
    onMounted(async ()=>{
        try {
            await nextTick();
            await nextAnimationFrame();
            await Promise.all([
                onLogin(props.isAppInit)
            ].concat(props.isAppInit ? [
                api.flush()
            ] : []));
            afterMount();
        } catch (error) {
            emit('load-error', error);
        }
        emit('loaded');
    });
    return {
        isEgsLoyaltyDoneModalVisible
    };
}
