export const createAutomationDirective = (param)=>{
    let { elemAttrName } = param;
    return {
        // Directive has a mounted and updated lifecycle hook
        mounted (el, binding) {
            if (binding.value && el instanceof Element) el.setAttribute(elemAttrName, `${binding.value}`);
        },
        updated (el, binding) {
            if (el instanceof Element) {
                if (binding.value) el.setAttribute(elemAttrName, `${binding.value}`);
                else el.removeAttribute(elemAttrName);
            }
        }
    };
};
