export const getTrackableEvents = ()=>new Set([
        'resize',
        'orientationchange',
        'keydown',
        'keyup',
        'scroll',
        'pointermove',
        'pointerdown',
        'pointerup',
        'visibilitychange'
    ]);
