import CustomerRouteAccessRole from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import RouteName from '@leon-hub/routing-config-names';
import { useRouterStore } from 'web/src/modules/core/store';
export default function performLogout(router) {
    const { currentRoute, getAnonymousRouteHistoryDelta } = useRouterStore();
    if (currentRoute?.meta.access === CustomerRouteAccessRole.AUTHORIZED) {
        const delta = getAnonymousRouteHistoryDelta();
        if (0 !== delta) router.go(delta);
        else router.replace({
            name: RouteName.HOME
        });
    }
}
