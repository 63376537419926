import { defineAsyncComponent } from 'vue';
import ComponentLoadError from '../components/ComponentLoadError/ComponentLoadError.vue';
import importComponent from './importComponent';
export default function getAsyncComponent(loader) {
    return defineAsyncComponent({
        loader: ()=>importComponent(loader),
        errorComponent: ComponentLoadError,
        timeout: 30000
    });
}
