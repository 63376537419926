import { defineStore } from 'pinia';
import { ref } from 'vue';
import { fetchApiSettings } from 'web/src/modules/core/store/utils/fetchApiSettings';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import useSyncState from './composables/useSyncState';
const useApiSettingsStore = defineStore('api-settings', ()=>{
    const api = useGraphqlClient();
    const graphqlBatchRequestsMaxSize = ref(0);
    function handleApiSettings(settings) {
        if (settings && graphqlBatchRequestsMaxSize.value !== settings.graphqlBatchRequestsMaxSize) {
            graphqlBatchRequestsMaxSize.value = settings.graphqlBatchRequestsMaxSize;
            api.setMaxBatchQueueSize(graphqlBatchRequestsMaxSize.value);
        }
    }
    async function updateApiSettings() {
        handleApiSettings(await fetchApiSettings());
    }
    useSyncState(updateApiSettings, 'api-settings');
    return {
        graphqlBatchRequestsMaxSize,
        handleApiSettings,
        updateApiSettings
    };
});
export default useApiSettingsStore;
