import { onMounted, ref, toRef } from 'vue';
import useWebPushNotificationsStore from 'web/src/modules/push-notifications/store/useWebPushNotificationsStore';
import useIntercomStore from 'web/src/modules/intercom/store/useIntercomStore';
export default function usePostponedAppController() {
    let isModalVisible = ref(false);
    "1";
    {
        const webPushNotificationsStore = useWebPushNotificationsStore();
        isModalVisible = toRef(webPushNotificationsStore, 'isModalVisible');
        onMounted(()=>{
            webPushNotificationsStore.showModal();
        });
    }
    "1";
    onMounted(()=>{
        useIntercomStore().initIntercom();
    });
    return {
        isModalVisible
    };
}
