import { Deferred } from '@leon-hub/utils';
export function useAsyncWrapper(asyncEsModuleCallback, getApi, useArgs) {
    const d = new Deferred();
    const cache = {};
    function wrapGetter(key) {
        if (!cache[key]) // eslint-disable-next-line func-names
        cache[key] = async function() {
            for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
                rest[_key] = arguments[_key];
            }
            const outerApi = await d.promise;
            if (void 0 === outerApi) throw new TypeError(`Unable to get property '${key}' of undefined`);
            return outerApi[key].apply(this, rest);
        };
        return cache[key];
    }
    if (void 0 !== asyncEsModuleCallback) {
        const asyncEsModule = asyncEsModuleCallback();
        const calculatedArgs = useArgs ? useArgs() : [];
        asyncEsModule.then((param)=>{
            let { useModule } = param;
            const result = useModule.call(null, getApi(), ...calculatedArgs);
            if (result instanceof Promise) result.then(d.resolve, d.reject);
            else d.resolve(result);
        });
    }
    return new Proxy({}, {
        get (target, key) {
            if ('string' == typeof key) return wrapGetter(key);
            throw new Error(`Unexpected module key: ${String(key)}`);
        }
    });
}
