import { Deferred } from '@leon-hub/utils';
import { setupOptions } from '../setupOptions';
import { getTrackableEvents } from './getTrackableEvents';
import { removeActivityListeners } from './removeActivityListeners';
import { addActivityListeners } from './addActivityListeners';
export const whenClientActivityIdle = async function() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const { interval, eager, signal } = setupOptions(options);
    const d = new Deferred();
    const startTime = Date.now();
    const trackableEvents = getTrackableEvents();
    const listenerOptions = {
        capture: true,
        passive: true,
        signal
    };
    let timeoutID;
    let lastActivityTime = startTime;
    function tearDown(result) {
        removeActivityListeners(trackableEvents, updateActivity, listenerOptions);
        clearInterval(timeoutID);
        d.resolve(result);
    }
    function isStableEnough(now) {
        return lastActivityTime > 0 && now - lastActivityTime > interval;
    }
    function updateActivity() {
        const now = Date.now();
        const timeSpent = startTime - now;
        if (eager) tearDown(timeSpent >= interval);
        else lastActivityTime = now;
    }
    function checkStable() {
        let { skipEager } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        const now = Date.now();
        if (isStableEnough(now)) tearDown(true);
        else if (!skipEager && eager) tearDown(false);
        else timeoutID = setTimeout(checkStable, interval);
    }
    addActivityListeners(trackableEvents, updateActivity, listenerOptions);
    signal.addEventListener('abort', ()=>tearDown(false));
    queueMicrotask(()=>checkStable({
            skipEager: true
        }));
    return d.promise.catch((reason)=>{
        // eslint-disable-next-line no-console
        console.error(reason);
        return false;
    }).then((result)=>result ? 0 : Date.now() - lastActivityTime);
};
