import { defineModule } from '@leon-hub/define-module';
import { normalizeError } from '@leon-hub/errors';
import { AppModuleError } from '../entities';
export function defineAppModule(name, cb) {
    return defineModule(function() {
        for(var _len = arguments.length, rest = new Array(_len), _key = 0; _key < _len; _key++){
            rest[_key] = arguments[_key];
        }
        const options = rest[0];
        try {
            return cb(options, ...rest.slice(1));
        } catch (err) {
            throw new AppModuleError({
                message: `Unable to initialize "${name}" module`,
                cause: normalizeError(err)
            });
        }
    });
}
