import checkPrefix from './checkPrefix';
import checkTrailingSlash from './checkTrailingSlash';
export default function checkLanguagePrefix(options) {
    let redirectUri = '';
    "1";
    "1";
    redirectUri = checkPrefix(redirectUri, options);
    redirectUri = checkTrailingSlash(redirectUri, options.currentUrlPrefix);
    return redirectUri;
}
