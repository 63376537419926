import { QuickBetsModule } from 'web/src/modules/quick-bets/QuickBetsModule';
import { SupportModule } from 'web/src/modules/support/SupportModule';
import { SportlineModule, SportlineSportsModule } from 'web/src/modules/sportline/SportlineModule';
import { SlipModule } from 'web/src/modules/slip/SlipModule';
import { HomeModule } from 'web/src/modules/home/HomeModule';
import { ReferralProgramModule } from 'web/src/modules/referral-program/ReferralProgramModule';
import { OnboardingModule } from 'web/src/modules/onboarding/OnboardingModule';
import { SimplifiedRegistrationModule } from 'web/src/modules/registration/submodules/simplified-registration';
import { FortuneWheelModule } from 'web/src/modules/fortune-wheel/FortuneWheelModule';
import { CasinoBetgamesModule } from 'web/src/modules/casino/submodules/betgames/CasinoBetgamesModule';
import { CasinoGroupsModule } from 'web/src/modules/casino/submodules/groups/CasinoGroupsModule';
import { CasinoGameModule } from 'web/src/modules/casino/submodules/game/CasinoGameModule';
import { ProfileBonusModule } from 'web/src/modules/profile/submodules/bonuses/ProfileBonusModule';
import { CasinoLobbyModule } from 'web/src/modules/casino/submodules/lobby/CasinoLobbyModule';
import { SearchModule } from 'web/src/modules/search/SearchModule';
import { CustomerNotificationsModule } from 'web/src/modules/customer-notifications/CustomerNotificationsModule';
import { PushNotificationsModule } from 'web/src/modules/push-notifications/PushNotificationsModule';
import { CasinoLoyaltyProgramModule } from 'web/src/modules/casino-loyalty-program/CasinoLoyaltyProgramModule';
import { DevToolsModule } from 'web/src/modules/dev-tools/DevToolsModule';
import { ProfileModule } from 'web/src/modules/profile/ProfileModule';
import { ErrorsModule } from 'web/src/modules/errors/ErrorsModule';
import { LoginModule } from 'web/src/modules/login/LoginModule';
import { AccessOptionsModule } from 'web/src/modules/access-options/AccessOptionsModule';
import { LandingsModule } from 'web/src/modules/landings/LandingsModule';
import { ResponsibleGamblingModule } from 'web/src/modules/profile/submodules/responsible-gambling/ResponsibleGamblingModule';
import { I18nModule } from 'web/src/modules/i18n/I18nModule';
import { RulesModule } from 'web/src/modules/rules/RulesModule';
import { PaymentsModule } from 'web/src/modules/payments/PaymentsModule';
import { PinCodeModule } from 'web/src/modules/pin-code/PinCodeModule';
import { AffiliatesModule } from 'web/src/modules/affiliates/AffiliatesModule';
import { RegistrationCompletionModule } from 'web/src/modules/registration-completion/RegistrationCompletionModule';
import { ThemeModule } from 'web/src/modules/theme/ThemeModule';
export default function getAppModules() {
    const modules = [
        new LoginModule(),
        new SupportModule(),
        new HomeModule(),
        new ProfileModule(),
        new ReferralProgramModule(),
        new OnboardingModule(),
        new FortuneWheelModule(),
        new ProfileBonusModule(),
        new SearchModule(),
        new CustomerNotificationsModule(),
        new PushNotificationsModule(),
        new DevToolsModule(),
        new ErrorsModule(),
        new AccessOptionsModule(),
        new LandingsModule(),
        new I18nModule(),
        new RulesModule(),
        new PaymentsModule(),
        new PinCodeModule(),
        new AffiliatesModule(),
        new ThemeModule()
    ];
    "1";
    modules.push(new SportlineModule(), new SportlineSportsModule(), new QuickBetsModule(), new SlipModule());
    "1";
    modules.push(new CasinoLoyaltyProgramModule());
    "1";
    modules.push(new ResponsibleGamblingModule());
    "1";
    modules.push(new SimplifiedRegistrationModule(), new RegistrationCompletionModule());
    "1";
    modules.push(new CasinoBetgamesModule(), new CasinoGameModule(), new CasinoGroupsModule(), new CasinoLobbyModule());
    return modules;
}
