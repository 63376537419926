import RouteName from '@leon-hub/routing-config';
import CustomerRouteAccessRole, { isCustomerRouteAccessRole } from '@leon-hub/routing-config/src/types/CustomerRouteAccessRole';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { isRouteAllowed } from '../utils';
function isOptionalAccess(value) {
    return !value || isCustomerRouteAccessRole(value);
}
export default function createAccessRestrictionsNavigationGuard(router) {
    router.beforeEach((to, from, next)=>{
        if (!to.name) {
            router.next(next, {
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return;
        }
        const { access } = to.meta;
        const { access: fromAccess } = from.meta;
        isOptionalAccess(access);
        isOptionalAccess(fromAccess);
        if (access === CustomerRouteAccessRole.FORBIDDEN) {
            router.next(next, {
                name: RouteName.ERROR_FORBIDDEN_403
            });
            return;
        }
        const { isLoggedIn } = useIsLoggedIn();
        if (!router.isModalReplaced() && !isRouteAllowed(access, isLoggedIn.value)) {
            if (isLoggedIn.value) router.next(next, {
                name: RouteName.HOME
            });
            else router.next(next, {
                name: RouteName.LOGIN,
                state: {
                    toRoute: to.fullPath
                }
            });
            return;
        }
        next();
    });
}
