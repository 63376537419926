import { computed, nextTick, ref, toRef, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useLayoutDesktopInfo } from 'web/src/modules/core/composables';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useLayoutScroll } from '../../composables';
export default function useLayoutDesktopDefault() {
    const currentRoute = useRoute();
    const content = ref();
    const isEgsStoryBannerEnabled = toRef(useSiteConfigStore(), 'isEgsStoryBannerEnabled');
    const { hasLeftSideBar, hasRightSideBar, isFullWidth } = useLayoutDesktopInfo();
    const hasFixedBar = computed(()=>!!currentRoute.meta.hasFixedBar);
    const isFooterVisible = computed(()=>!currentRoute.meta.isFooterHidden);
    const isEgsLobby = computed(()=>!!currentRoute.meta.isEgsLobbyPage);
    const isStoryBanner = computed(()=>isEgsStoryBannerEnabled.value && isEgsLobby.value);
    const isBannerVisible = computed(()=>!currentRoute.meta.isBannerHidden);
    const { restoreScrollPosition } = useLayoutScroll(content);
    watch(()=>currentRoute.fullPath, async ()=>{
        await nextTick();
        if (!currentRoute.meta.doSaveScrollPosition) await restoreScrollPosition();
    });
    return {
        content,
        hasFixedBar,
        hasLeftSideBar,
        hasRightSideBar,
        isFullWidth,
        isStoryBanner,
        isEgsLobby,
        isFooterVisible,
        isBannerVisible
    };
}
