import { defineStore } from 'pinia';
import { ref } from 'vue';
const useModuleTimersStore = defineStore('module-timers', ()=>{
    const defaultTimeout = ref(60000);
    const moduleTimeouts = ref({});
    function setDefaultTimeout(value) {
        defaultTimeout.value = value;
    }
    function setModuleTimeouts(value) {
        moduleTimeouts.value = value;
    }
    return {
        defaultTimeout,
        moduleTimeouts,
        setDefaultTimeout,
        setModuleTimeouts
    };
});
export default useModuleTimersStore;
