import { onActivated, onBeforeMount, onBeforeUnmount, onDeactivated } from 'vue';
import useEventsBus from './useEventsBus';
function getOptions(options) {
    if ('object' == typeof options) return options;
    return {
        onDeactivatedDisabled: false,
        onBeforeMountDisabled: options
    };
}
/**
 * Safely subscribes to an event when the component is ready to handle event callback.
 * Auto-sync with component life cycle.
 */ export default function useBusSafeSubscribe(event, callback) {
    let skipDeactivationOrOptions = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    /**
   * Skip subscription during Server-Side Rendering (SSR)
   * as the subsequent Vue lifecycle hooks won't be called on the server.
   */ const { onBeforeMountDisabled, onDeactivatedDisabled } = getOptions(skipDeactivationOrOptions);
    const bus = useEventsBus();
    let isActive = false;
    const subscribe = ()=>{
        if (!isActive) {
            bus.on(event, callback);
            isActive = true;
        }
    };
    const unsubscribe = ()=>{
        if (isActive) {
            bus.off(event, callback);
            isActive = false;
        }
    };
    /**
   * Register lifecycle hooks.
   * @see https://vuejs.org/api/composition-api-lifecycle.html
   */ if (!onBeforeMountDisabled) onBeforeMount(subscribe);
    onActivated(subscribe);
    onBeforeUnmount(unsubscribe);
    if (!onDeactivatedDisabled) onDeactivated(unsubscribe);
    return {
        subscribe,
        unsubscribe
    };
}
