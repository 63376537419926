import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { defineAsyncComponent } from 'vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import { VEmpty } from 'web/src/components/Empty';
import { useAuthorizedSnackbars } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AuthorizedSnackbars',
    setup (__props) {
        const TheDowntimeAlert = defineAsyncComponent(()=>import('web/src/modules/downtime/components/TheDowntimeAlert/TheDowntimeAlert.vue'));
        const VideoVerificationAlert = defineAsyncComponent(()=>import('web/src/modules/front-notifications/components/VideoVerificationAlert/VideoVerificationAlert.vue'));
        const { isDowntimeAlertVisible, isIdentificationStatusAlertVisible, isVideoVerificationAlert } = useAuthorizedSnackbars();
        return (_ctx, _cache)=>(_openBlock(), _createElementBlock(_Fragment, null, [
                _unref(isDowntimeAlertVisible) ? (_openBlock(), _createBlock(_unref(TheDowntimeAlert), {
                    key: 0
                })) : _createCommentVNode("", true),
                _createCommentVNode("", true),
                _unref(isVideoVerificationAlert) ? (_openBlock(), _createBlock(_unref(VideoVerificationAlert), {
                    key: 2
                })) : _createCommentVNode("", true)
            ], 64));
    }
});
