import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import { ChunkLoadError } from '@leon-hub/routing-config';
import { sleep } from '@leon-hub/utils';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
export default function importComponent(importFunction) {
    const { showConnectionError } = useSnackbarsStore();
    return new Promise((resolve, reject)=>{
        const retry = function() {
            let counter = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1;
            return importFunction().then((result)=>{
                resolve(result.default);
            }).catch((error)=>{
                if (counter < 3) {
                    sleep(1000).then(()=>{
                        retry(counter + 1);
                    });
                    return;
                }
                logger.error(normalizeError(error));
                showConnectionError().then(()=>{
                    retry();
                }).catch(()=>{
                    reject(new ChunkLoadError({
                        message: error.message,
                        originalError: error,
                        chunkPath: ''
                    }));
                });
            });
        };
        retry();
    });
}
