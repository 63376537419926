import { Sentry } from '@leon-hub/sentry';
export function addPageLifeCycleBreadcrumb(eventName) {
    let additionalData = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    Sentry.addBreadcrumb({
        category: 'page-lifecycle',
        message: `${eventName} event triggered`,
        level: 'info',
        data: {
            ...additionalData
        }
    });
}
