import { onBeforeUnmount, onMounted, watch, toRef, nextTick, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useLifecycleResizeObserver } from '@leon-hub/vue-utils';
import { BusEvent, useEventsBus, useBusSafeSubscribe } from '@leon-hub/event-bus';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { useRootStore, useScrollStore } from 'web/src/modules/core/store';
const disableScrollClassName = 'disable-scroll';
const phoneFixedStyleClassName = 'fixed-scroll-styles';
export default function useLayoutScroll(content) {
    const mainContent = ref();
    const bus = useEventsBus();
    const { setHasScrollableContent, setScrollTop } = useScrollStore();
    let lastScrollTop = 0;
    function getContentScrollElement() {
        return content.value instanceof Window ? window.document.documentElement : content.value;
    }
    function handleScroll() {
        const target = getContentScrollElement();
        if (!target) return;
        setScrollTop(target.scrollTop);
        bus.emit(BusEvent.LAYOUT_CONTENT_SCROLL, {
            scrollTop: target.scrollTop,
            offsetHeight: target.offsetHeight,
            scrollHeight: target.scrollHeight
        });
    }
    function scrollContent(top) {
        let smooth = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        try {
            content.value?.scrollTo({
                left: 0,
                top: 0 === top ? -1 : top,
                behavior: smooth ? 'smooth' : 'auto'
            });
        } catch  {}
    }
    const disabledScrollCounters = {};
    // eslint-disable-next-line no-param-reassign
    function disableScroll(param) {
        let { reason } = param;
        try {
            if (!disabledScrollCounters[reason]) disabledScrollCounters[reason] = 0;
            disabledScrollCounters[reason] += 1;
            const contentScroll = getContentScrollElement();
            contentScroll?.classList.add(disableScrollClassName);
        } catch  {}
    }
    function enableScroll(param) {
        let { reason } = param;
        try {
            if (disabledScrollCounters[reason]) {
                disabledScrollCounters[reason] -= 1;
                if (disabledScrollCounters[reason] <= 0) delete disabledScrollCounters[reason];
            }
            const totalCounter = Object.values(disabledScrollCounters).reduce((accumulator, item)=>accumulator + item, 0);
            if (!totalCounter) {
                const contentScroll = getContentScrollElement();
                contentScroll?.classList.remove(disableScrollClassName);
            }
        } catch  {}
    }
    async function restoreScrollPosition() {
        const nextTopValue = window.history.state?.scrollTop || 0;
        // instant setup scroll position for the same layout
        content.value?.scrollTo({
            top: nextTopValue
        });
        if (nextTopValue > 0) {
            await nextTick();
            await nextAnimationFrame();
            // await updating position after change layout
            content.value?.scrollTo({
                top: nextTopValue
            });
        }
    }
    onMounted(()=>{
        content.value?.addEventListener('scroll', handleScroll);
    });
    onBeforeUnmount(()=>{
        content.value?.removeEventListener('scroll', handleScroll);
    });
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL_TOP, (param)=>{
        let { smooth } = param;
        scrollContent(0, smooth);
    });
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SET_SCROLL, (param)=>{
        let { scrollTop, smooth } = param;
        scrollContent(scrollTop, smooth);
    });
    useBusSafeSubscribe(BusEvent.SCROLL_TO_ELEMENT_ID, (param)=>{
        let { id, inModal, smooth } = param;
        if (inModal) return;
        const element = document.getElementById(id);
        if (!element) return;
        scrollContent(element.offsetTop, smooth);
    });
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE, disableScroll);
    useBusSafeSubscribe(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, enableScroll);
    const isAppMainContentLoaded = toRef(useRootStore(), 'isAppMainContentLoaded');
    const route = useRoute();
    async function updateScrollContent() {
        await nextTick();
        const target = getContentScrollElement();
        if (target) setHasScrollableContent(target.offsetHeight < target.scrollHeight);
    }
    watch(()=>route.path, updateScrollContent);
    watch(isAppMainContentLoaded, updateScrollContent, {
        immediate: true
    });
    useLifecycleResizeObserver(mainContent, updateScrollContent);
    return {
        mainContent,
        restoreScrollPosition
    };
}
