import { ApiConnectionError, ApiTechnicalError, ApiServiceUnavailableError, ApiRequestAbortedError, GqlApiAccessDeniedError } from '@leon-hub/api';
import { BusEvent } from '@leon-hub/event-bus';
import { AccessDeniedRemoteApiExceptionCode } from '@leon-hub/api-sdk';
export default function initErrorsInterface(bus) {
    const emitError = (error)=>{
        bus.emit(BusEvent.API_ERROR, {
            error
        });
    };
    window.mainApp = window.mainApp || {};
    window.mainApp.errors = {
        apiConnectionError: ()=>emitError(new ApiConnectionError()),
        apiTechnicalError: ()=>emitError(new ApiTechnicalError()),
        apiServiceUnavailableError: ()=>emitError(new ApiServiceUnavailableError()),
        apiRequestAbortedError: ()=>emitError(new ApiRequestAbortedError()),
        apiAccessDeniedError: ()=>emitError(new GqlApiAccessDeniedError({
                extensions: {
                    errorCode: AccessDeniedRemoteApiExceptionCode.ACCESS_DENIED
                }
            })),
        apiCustomError: (error)=>emitError(error ?? new Error('Custom public API error'))
    };
}
