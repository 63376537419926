import { computed } from 'vue';
import { useRoute } from 'vue-router';
import useLayoutDesktopInfo from './useLayoutDesktopInfo';
export default function useLayoutInfo() {
    const currentRoute = useRoute();
    let isFullWidth;
    "1";
    isFullWidth = useLayoutDesktopInfo().isFullWidth;
    const isLanding = computed(()=>!!currentRoute.meta.isLanding);
    const isBlank = computed(()=>!!currentRoute.meta.isBlank);
    return {
        isFullWidth,
        isLanding,
        isBlank
    };
}
