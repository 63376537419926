import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
export const useDesktopModalStore = defineStore('desktop-modal', ()=>{
    const desktopModal = ref();
    const isProfileLayout = computed(()=>!!desktopModal.value?.isProfile);
    const hasDesktopModal = computed(()=>!!desktopModal.value);
    const closeModalEvents = ref([]);
    function setDesktopModal(value) {
        desktopModal.value = value;
    }
    function addCloseEventListener(callback) {
        closeModalEvents.value.push(callback);
    }
    function removeCloseEventListener(callback) {
        closeModalEvents.value = closeModalEvents.value.filter((c)=>c !== callback);
    }
    return {
        desktopModal,
        hasDesktopModal,
        isProfileLayout,
        closeModalEvents,
        addCloseEventListener,
        removeCloseEventListener,
        setDesktopModal
    };
});
