import { computed, provide, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { ModalProvidable } from 'web/src/modules/dialogs/enums';
import { useDesktopModalStore, useRouterStore } from 'web/src/modules/core/store';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useDialogsStore } from 'web/src/modules/dialogs/store';
import { getModalHeight, getModalWidth, getModalPreset } from './utils';
export default function useDesktopModal() {
    const dialogsStore = useDialogsStore();
    const routerStore = useRouterStore();
    const currentDialog = toRef(dialogsStore, 'currentDialog');
    const hasInnerDialog = toRef(dialogsStore, 'hasInnerDialog');
    const bus = useEventsBus();
    const currentRouteName = toRef(routerStore, 'currentRouteName');
    const desktopModal = toRef(useDesktopModalStore(), 'desktopModal');
    const { isLoggedIn } = useIsLoggedIn();
    const router = useRouter();
    const hasInnerModal = computed(()=>!!currentDialog.value || hasInnerDialog.value);
    const modalPreset = computed(()=>getModalPreset({
            preset: desktopModal.value?.preset,
            isLoggedIn: isLoggedIn.value
        }));
    const modalHeight = computed(()=>getModalHeight(modalPreset.value));
    const modalWidth = computed(()=>getModalWidth(modalPreset.value));
    const showSideBar = computed(()=>!!desktopModal.value?.isProfile && isLoggedIn.value);
    const isNoMinHeightLimit = computed(()=>!!desktopModal.value?.isNoMinHeightLimit);
    provide(ModalProvidable.modalContext, {
        insideModal: true
    });
    function onOverlayClicked() {
        if (modalPreset.value === ModalDesktopPreset.SearchDesktopModal) router.closeModal();
    }
    function onInnerScroll(status) {
        bus.emit(BusEvent.MODAL_CONTENT_SCROLL, {
            ...status
        });
    }
    return {
        isNoMinHeightLimit,
        modalWidth,
        modalHeight,
        modalPreset,
        hasInnerModal,
        desktopModal,
        showSideBar,
        currentRouteName,
        onOverlayClicked,
        onInnerScroll
    };
}
