import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { AppEmitter } from '@leon-hub/app-emitter';
import { DefaultDebugLogger } from '@leon-hub/debug-logger';
import { logger } from '@leon-hub/logging';
import { normalizeError } from '@leon-hub/errors';
import { isDebugEnabled } from '@leon-hub/debug';
let EventsBus = class EventsBus {
    initialize() {
        if (!this.initialized) {
            this.log('initialization');
            this.initialized = true;
            this.runQueue();
        }
    }
    getEmitter() {
        return this.appEmitter;
    }
    off(event, callback) {
        let events = [];
        if (event) events = Array.isArray(event) ? event : [
            event
        ];
        if (this.initialized) {
            if (events.length) this.removeEventsByEvent(events, callback);
            else throw new Error('Removing all events is not allowed');
        } else this.addQueue({
            type: 'off',
            value: [
                events,
                callback
            ]
        });
    }
    on(event, callback) {
        const events = Array.isArray(event) ? event : [
            event
        ];
        if (this.initialized) for (const item of events){
            this.log(`starting event listener for event "${item}" at ${new Date(Date.now()).toISOString()}`);
            this.appEmitter.on(String(item), callback);
        }
        else this.addQueue({
            type: 'on',
            value: [
                events,
                callback
            ]
        });
    }
    once(event, callback) {
        const events = Array.isArray(event) ? event : [
            event
        ];
        if (this.initialized) for (const item of events){
            this.log(`starting one time listener for event "${item}" at ${new Date(Date.now()).toISOString()}`);
            this.appEmitter.once(`${item}`, callback);
        }
        else this.addQueue({
            type: 'once',
            value: [
                events,
                callback
            ]
        });
    }
    emit(event, payload) {
        if (this.initialized) {
            // dont call JSON.stringify without debug
            if (this.debug) this.log(`emitting event "${event}" with payload:
          ${JSON.stringify(payload)} at ${new Date(Date.now()).toISOString()}`);
            this.appEmitter.emit(`${event}`, payload);
        } else this.addQueue({
            type: 'emit',
            value: [
                event,
                payload
            ]
        });
    }
    removeEventsByEvent(events, callback) {
        try {
            for (const item of events){
                this.log(`removing event listener for the event "${item}" at ${new Date(Date.now()).toISOString()}`);
                this.appEmitter.off(String(item), callback);
            }
        } catch (rawError) {
            const error = normalizeError(rawError);
            logger.error(new Error(`${this.logPrefix}, Can't unsubscribe ${error.message}`));
        }
    }
    runQueue() {
        const order = [
            'on',
            'once',
            'off',
            'emit'
        ];
        const orderedQueue = this.queue.sort((a, b)=>order.indexOf(a.type) - order.indexOf(b.type));
        for (const item of orderedQueue){
            if ('on' === item.type) this.on(...item.value);
            if ('once' === item.type) this.once(...item.value);
            if ('off' === item.type) this.off(...item.value);
            if ('emit' === item.type) this.emit(...item.value);
        }
        this.queue = [];
    }
    addQueue(item) {
        const items = item.value[0];
        if (items) {
            const events = Array.isArray(items) ? items : [
                items
            ];
            for (const event of events)this.log(`delay "${item.type}" handler for "${event}" event at ${new Date(Date.now()).toISOString()}`);
        } else this.log(`delay "${item.type}" handler at ${new Date(Date.now()).toISOString()}`);
        this.queue.push(item);
    }
    log() {
        for(var _len = arguments.length, messages = new Array(_len), _key = 0; _key < _len; _key++){
            messages[_key] = arguments[_key];
        }
        if (this.debug) this.logger.log(this.logPrefix, ...messages);
    }
    constructor(){
        _define_property(this, "logPrefix", '[event-bus]');
        _define_property(this, "initialized", false);
        _define_property(this, "appEmitter", new AppEmitter());
        _define_property(this, "queue", []);
        _define_property(this, "debug", isDebugEnabled());
        _define_property(this, "logger", DefaultDebugLogger.create({
            namespace: 'events-bus'
        }));
    }
};
export { EventsBus as default };
