import { defineStore } from 'pinia';
import { useLocalStorageManager } from '@leon-hub/local-storage';
import { useCookies } from 'web/src/modules/core/composables';
import useInitAppConfigStore from './useInitAppConfigStore';
import { StorageKeys } from './enums';
const storageKeysToSave = [];
const useStorageManagerStore = defineStore('storage-manager', ()=>{
    const { themeConfig } = useInitAppConfigStore();
    const cookieManager = useCookies();
    const localStorageManager = useLocalStorageManager();
    const initValues = {};
    if (void 0 !== themeConfig.current) initValues[StorageKeys.THEME] = themeConfig.current;
    if (void 0 !== themeConfig.first) initValues[StorageKeys.FIRST_THEME] = themeConfig.first;
    async function set(key, value) {
        if (initValues[key] !== value) {
            initValues[key] = value;
            localStorageManager.setItem(key, value);
            await cookieManager.set({
                name: key,
                value
            });
        }
    }
    async function get(key) {
        if (void 0 !== initValues[key]) return initValues[key];
        const item = await cookieManager.get(key) || '';
        initValues[key] = item;
        return item;
    }
    function setTheme(value) {
        return set(StorageKeys.THEME, value);
    }
    async function getTheme() {
        const theme = await get(StorageKeys.THEME);
        return theme.toUpperCase();
    }
    function setFirstTheme(value) {
        return set(StorageKeys.FIRST_THEME, value);
    }
    async function getFirstTheme() {
        const firstTheme = await get(StorageKeys.FIRST_THEME);
        return firstTheme.toUpperCase();
    }
    function setIsAutoTheme(value) {
        return set(StorageKeys.IS_AUTO_THEME, value.toString());
    }
    async function getIsAutoTheme() {
        return await get(StorageKeys.IS_AUTO_THEME) === 'true';
    }
    function clearCache() {
        localStorageManager.clear(storageKeysToSave);
    }
    return {
        getTheme,
        setTheme,
        getFirstTheme,
        setFirstTheme,
        getIsAutoTheme,
        setIsAutoTheme,
        clearCache,
        // @deprecated
        get,
        // @deprecated
        set
    };
});
export default useStorageManagerStore;
