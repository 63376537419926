import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import SnackbarHolder from 'web/src/modules/snackbars/components/SnackbarHolder/SnackbarHolder.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LayoutLanding',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _component_router_view = _resolveComponent("router-view");
            return _openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_component_router_view),
                _createVNode(SnackbarHolder)
            ], 64);
        };
    }
});
