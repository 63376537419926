import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { storeToRefs } from 'pinia';
import { ref, provide, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useRouterStore, useSiteConfigStore } from 'web/src/modules/core/store';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import VCmsContentSeo from 'web/src/modules/cms/components/VCmsContentSeo/VCmsContentSeo.vue';
import useSeoMetaStorage from 'web/src/modules/seo/store/useSeoMetaStorage';
import { getCmsKeys, getCmsParams } from 'web/src/modules/cms/utils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FooterSeo',
    setup (__props) {
        const route = useRoute();
        const { seoConfigs } = storeToRefs(useRouterStore());
        const { metaParameters } = storeToRefs(useSeoMetaStorage());
        const { isSeoBetCmsSpintaxEnabled: isSpintaxCms } = storeToRefs(useSiteConfigStore());
        const cmsData = ref({
            keys: []
        });
        watch(()=>route, (newRoute)=>{
            const newCmsData = {
                keys: getCmsKeys(newRoute.name, newRoute.params, seoConfigs.value)
            };
            if (isSpintaxCms.value) {
                newCmsData.params = getCmsParams(newRoute.name, newRoute.params, seoConfigs.value);
                newCmsData.cacheKey = Date.now().toString();
            }
            cmsData.value = newCmsData;
        }, {
            deep: true,
            immediate: true
        });
        provide('getMetaData', ()=>metaParameters.value || {});
        return (_ctx, _cache)=>cmsData.value.keys.length ? (_openBlock(), _createBlock(VCmsContent, {
                key: 1,
                "cms-data": cmsData.value,
                "spintax-cms": _unref(isSpintaxCms),
                silent: ""
            }, null, 8, [
                "cms-data",
                "spintax-cms"
            ])) : _createCommentVNode("", true);
    }
});
