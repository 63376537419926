import { localStorageManager } from '@leon-hub/local-storage';
import { featureKey } from '../constants';
export const enableAutomationPlugin = ()=>{
    let isChanged = false;
    if (!localStorageManager.getItem(featureKey)) {
        localStorage.setItem(featureKey, '1');
        isChanged = true;
    }
    return isChanged;
};
