import { computed, nextTick, ref } from 'vue';
import { useLifecycleResizeObserver } from '@leon-hub/vue-utils';
import RouteName from '@leon-hub/routing-config-names';
export default function useDesktopModalHeight() {
    const sidebar = ref();
    const sidebarHeight = ref();
    const contentStyles = computed(()=>sidebarHeight.value && !RouteName.CMS_PROMO_TERMS ? {
            maxHeight: `${sidebarHeight.value}px`
        } : void 0);
    useLifecycleResizeObserver(sidebar, async ()=>{
        await nextTick();
        sidebarHeight.value = sidebar.value?.scrollHeight;
    });
    return {
        sidebar,
        contentStyles
    };
}
