import { DEFAULT_INTERVAL, DEFAULT_TIMEOUT } from '../constants';
function mergeAbortSignals() {
    for(var _len = arguments.length, signals = new Array(_len), _key = 0; _key < _len; _key++){
        signals[_key] = arguments[_key];
    }
    signals.length;
    const controller = new AbortController();
    function onAbort() {
        controller.abort();
    }
    for (const signal of signals)signal.addEventListener('abort', onAbort);
    return controller.signal;
}
function normalizeSignal(signal) {
    return signal ?? new AbortController().signal;
}
export function setupOptions() {
    let { interval = DEFAULT_INTERVAL, timeout = DEFAULT_TIMEOUT, eager = false, idlers = [], signal } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const isTimeoutDefined = timeout > 0 && Number.isFinite(timeout);
    return {
        interval,
        timeout,
        eager,
        idlers,
        signal: isTimeoutDefined ? mergeAbortSignals(normalizeSignal(signal), createAbortSignalTimeout(timeout)) : normalizeSignal(signal)
    };
}
function createAbortSignalTimeout(timeout) {
    const controller = new AbortController();
    setTimeout(()=>{
        controller.abort();
    }, timeout);
    return controller.signal;
}
