import { logger } from '@leon-hub/logging';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
export function startAppSw() {
    return import('web/src/modules/service-worker/utils').then((param)=>{
        let { initSw } = param;
        return initSw();
    }).catch((error)=>{
        const appError = useErrorsConverter().convertToBaseError(error, 'Unexpected service-worker error');
        appError.silent = true;
        logger.error(appError);
    });
}
